<template>
  <div>
    <slot name="button"> </slot>
    <v-dialog v-model="dialog" persistent :width="returnWidth">
      <v-slide-x-transition :hide-on-leave="true">
        <AddSaver
          @closeModal="closeModal"
          @closeAddSaverModal="closeAddSaverModal"
          @openProfileModal="openProfileModal"
          v-show="addSaverModal"
        />
      </v-slide-x-transition>

      <!-- <VerifySaver
      :dialog="verifySaverModal"
      @openModal="openProfileModal"
      @closeModal="closeVerifySaverModal"
    /> -->
      <v-slide-x-transition :hide-on-leave="true">
        <component
          :is="returnProfile"
          v-show="profileModal"
          @closeProfileModal="closeProfileModal"
          @openAddSaverModal="openAddSaverModal"
          @closeModal="closeModal"
          @updateStep="updateStep"
        ></component>
      </v-slide-x-transition>
    </v-dialog>
  </div>
</template>

<script>
import AddSaver from "./modal/AddSaver";
import NextOfKin from "./modal/profile/NextOfKin.vue";
import CreateAPiggy from "./modal/profile/CreatePiggy.vue";
import VerifySaver from "./modal/VerifySaver.vue";
import Details from "./modal/profile/Details.vue";
export default {
  data() {
    return {
      step: 1,
      addSaverModal: false,
      verifySaverModal: false,
      profileModal: false,
      dialog: false,
    };
  },
  props: {
    open: Boolean,
  },
  watch: {
    open: function (newValue, oldValue) {
      if (newValue) {
        this.addSaverModal = true;
        this.dialog = true;
      } else if (!newValue) {
        this.addSaverModal = true;
        this.dialog = true;
      }
    },
  },
  components: {
    AddSaver,
    VerifySaver,
    NextOfKin,
    CreateAPiggy,
    Details,
  },
  methods: {
    updateStep(step) {
      this.step = step;
    },

    closeModal() {
      this.dialog = false;
      this.addSaverModal = false;
      this.profileModal = false;
      this.step = 1;
    },
    openAddSaverModal() {
      this.addSaverModal = true;
    },
    closeAddSaverModal() {
      this.addSaverModal = false;
    },
    openVerifySaverModal() {
      this.verifySaverModal = true;
    },
    closeVerifySaverModal() {
      this.verifySaverModal = false;
    },
    openProfileModal() {
      this.profileModal = true;
    },
    closeProfileModal() {
      this.profileModal = false;
    },
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
    returnProfile() {
      if (this.step === 1) {
        return NextOfKin;
      } else if (this.step === 2) {
        return CreateAPiggy;
      } else if (this.step === 3) {
        return Details;
      }
    },
  },
};
</script>

<style scoped>
</style>