<template>
  <v-row>
    <v-col md="9" sm="12" cols="12">
      <v-card class="card_left" rounded="lg">
        <div class="d-flex card_header mt-4 pa-6">
          <div>
            <h4>Manage Payments</h4>
            <p>
              Buy airtime & data, pay for electricity and cable network easily
            </p>
          </div>
        </div>
        <div class="d-flex payment_actions my-4 px-6">
          <div class="pay_cable d-flex" @click="openAddFunds">
            <span class="mx-4">
              <h5>Deposit</h5>
              <h5>Money</h5>
              <AddMoney v-show="false" ref="addmoney" />
            </span>
          </div>
          <div class="pay_cable d-flex" @click.prevent="openWithdraw">
            <span class="mx-4">
              <h5>Withdraw</h5>
              <h5>Money</h5>
              <WithdrawFunds v-show="false" ref="withdraw" />
            </span>
          </div>
          <div class="buy_airtime d-flex" @click.prevent="openAirtimeModal()">
            <img src="@/assets/airtime.svg" height="40" contain />
            <span class="mx-4">
              <h5>Buy</h5>
              <h5>Airtime & Data</h5>
            </span>
          </div>
          <div
            class="buy_electricity d-flex"
            @click.prevent="openElectricityModal()"
          >
            <img src="@/assets/electricity.svg" height="40" contain />
            <span class="mx-4">
              <h5>Buy</h5>
              <h5>Electricity</h5>
            </span>
          </div>
          <div class="pay_cable d-flex" @click.prevent="openCableModal()">
            <img src="@/assets/cable.svg" height="40" contain />
            <span class="mx-4">
              <h5>Pay</h5>
              <h5>Cable Tvs</h5>
            </span>
          </div>
        </div>
        <Airtime :dialog="airtimeDialog" @closeModal="closeAirtimeModal" />
        <Electricity
          :dialog="electricityDialog"
          @closeModal="closeElectricityModal"
        />
        <Cable :dialog="cableDialog" @closeModal="closeCableModal" />
        <v-divider></v-divider>
        <Transactions />
      </v-card>
    </v-col>
    <v-col md="3" sm="12" cols="12">
      <v-card class="card_right" rounded="lg">
        <AddSaver class="my-2" />
        <CreatePiggy class="my-2" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CreatePiggy from "../dashboard/quickActions/CreatePiggy.vue";
import MakePayment from "../dashboard/quickActions/PayBills.vue";
import AddSaver from "../dashboard/quickActions/AddSaver";
import Transactions from "./Transactions.vue";
import Airtime from "./modals/Airtime.vue";
import Electricity from "./modals/Electricity.vue";
import Cable from "./modals/Cable.vue";
import AddMoney from "../dashboard/balanceCard/AddMoney";
import WithdrawFunds from "../dashboard/balanceCard/WithdrawFunds";
export default {
  data() {
    return {
      airtimeDialog: false,
      electricityDialog: false,
      cableDialog: false,
      page: 1,
      filteredSearch: [],

      search: "",
    };
  },
  components: {
    CreatePiggy,
    MakePayment,
    AddSaver,
    Transactions,
    Airtime,
    Electricity,
    Cable,
    AddMoney,
    WithdrawFunds,
  },
  methods: {
    openAddFunds() {
      this.$refs.addmoney.$children[0].$children[0].$el.click();
    },
    openWithdraw() {
      this.$refs.withdraw.$children[0].$children[0].$el.click();
    },
    returnIntials(user) {
      let stringArray = user.split(" ");
      let firstInitial = stringArray[0].substring(0, 1);
      let secondInitial = stringArray[1].substring(0, 1);
      return firstInitial + secondInitial;
    },
    openAirtimeModal() {
      this.airtimeDialog = true;
    },
    closeAirtimeModal() {
      this.airtimeDialog = false;
    },
    openElectricityModal() {
      this.electricityDialog = true;
    },
    closeElectricityModal() {
      this.electricityDialog = false;
    },
    openCableModal() {
      this.cableDialog = true;
    },
    closeCableModal() {
      this.cableDialog = false;
    },
  },
  computed: {
    returnSearch() {
      if (this.search !== "") {
        this.filteredSearch = this.savers.filter(
          (saver) =>
            saver.fullname.toLowerCase().indexOf(this.search.toLowerCase()) !==
            -1
        );
        return this.filteredSearch;
      } else {
        this.filteredSearch = [];
        return this.filteredSearch;
      }
    },

    paginatedSavers() {
      let data = [...this.savers];
      return data.slice(this.start, this.end);
    },
    pageCount() {
      if (this.filteredSearch.length > 0) {
        return Math.ceil(this.filteredSearch.length / 4);
      } else {
        return Math.ceil(this.savers.length / 4);
      }
    },
    paginatedFilteredSavers() {
      let data = [...this.filteredSearch];
      return data.slice(this.start, this.end);
    },
    start() {
      let a = this.page * 4;
      let b = 4;
      return a - b;
    },
    end() {
      return this.page * 4;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #5e5e5e !important;
}

p {
  color: rgb(165, 165, 165);
  font-size: 12px;
  font-weight: 500;
}
.savers_details {
  position: relative;
  height: 40vh;
  overflow-y: auto;
}
.card_left
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}

.buy_airtime,
.buy_electricity,
.pay_cable {
  background-color: #ffffff;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}

.buy_airtime,
.buy_electricity,
.pay_cable :hover {
  cursor: pointer;
}
.payment_actions {
  contain: content;
  overflow-x: auto;
}
.payment_actions div > p {
  font-size: 12px;
}
.card_left {
  position: relative;

  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.card_right {
  padding: 20px 10px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
  height: 30vh;
}
.btn_header {
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}
</style>