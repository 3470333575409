<template>
  <v-row>
    <v-col md="9" sm="12">
      <v-card class="card_left" rounded="lg">
        <div class="mt-4 d-flex">
          <div md="7" sm="6">
            <h4 class="top_card_title">Stats</h4>
          </div>
        </div>

        <div class="d-flex insight_cards mt-2">
          <div class="all_saver">
            <p>ALL SAVERS</p>
            <h3>{{ getStatistics.all_savers }}</h3>
          </div>
          <div class="active_saver">
            <p>ALL AGENTS</p>
            <h3>{{ getStatistics.no_of_agents }}</h3>
          </div>
          <div class="active_saver">
            <p>ALL TRANSACTIONS</p>
            <h4>50000</h4>
          </div>
        </div>

        <div v-if="!(transactions.length > 0)" class="my-6">
          <h5>No Transaction</h5>
        </div>
        <div v-else>
          <v-text-field
            class="mt-4"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            solo
            label="Search"
          ></v-text-field>
          <v-divider class="my-4"></v-divider>
          <div class="agents md-px-6">
            <h5 class="mt-4">Transactions</h5>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="transactions"
              :single-select="singleSelect"
              :search="search"
              item-key="agent"
              show-select
              :loading="loading"
              class="elevation-1 agent_table"
              mobile-breakpoint="0"
              @click:row="(agent) => agentDetails(agent)"
            >
              <template v-slot:[`item.agent`]="{ item }">
                <div class="d-flex agent_details">
                  <v-avatar color="#EEECFF" size="40">
                    <h5>
                      <!-- {{
                      returnIntials(
                        (agent = item.first_name + " " + item.last_name)
                      )
                    }} -->
                      AG
                    </h5>
                  </v-avatar>
                  <div class="ml-2">
                    <h4>Agent</h4>
                    <p>{{ item.phone }}</p>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.balance`]="{ item }">
                <h5>
                  <v-icon color="primary" small> mdi-currency-ngn </v-icon
                  >{{ item.balance }}
                </h5>
              </template>
              <template v-slot:[`item.savers`]="{ item }">
                <h5>
                  {{ item.savers }}
                </h5>
              </template>
              <template v-slot:[`item.last_transaction`]="{ item }">
                <h5>
                  {{ formatDate(item.last_transaction) }}
                </h5>
              </template>
              <template v-slot:[`item.created`]="{ item }">
                <h5>
                  {{ formatDate(item.created) }}
                </h5>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col md="3" sm="12"> </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { getAgents, getStats } from "../../services/api-services";

export default {
  data() {
    return {
      openSuspendModal: false,
      loading: false,
      open: false,
      selected: [],
      step: 1,
      addSaverModal: false,
      verifySaverModal: false,
      profileModal: false,
      singleSelect: false,
      page: 1,
      filteredSearch: [],
      headers: [
        {
          text: "Transaction",
          align: "start",
          sortable: false,
          value: "agent",
        },

        { text: "Amount", value: "amount" },
      ],
      transactions: [],
      search: "",
    };
  },
  components: {},
  created() {
    this.getAllAgents();
    this.getAllStats();
  },

  methods: {
    ...mapMutations("admin", ["updateAgents", "updateStats"]),

    formatDate(date) {
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let month = newDate.getMonth() + 1;
      let dt = newDate.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      return dt + "/" + month + "/" + year;
    },
    suspendAgent() {
      this.openSuspendModal = !this.openSuspendModal;
    },
    returnIntials(user) {
      let stringArray = user.split(" ");
      let firstInitial = stringArray[0].substring(0, 1);
      let secondInitial = stringArray[1].substring(0, 1);
      return firstInitial.toUpperCase() + secondInitial.toUpperCase();
    },
    getAllStats() {
      this.statsLoading = true;
      getStats().then(
        (res) => {
          this.statsLoading = false;
          this.updateStats(res.data);
        },
        (err) => {
          this.statsLoading = false;
        }
      );
    },
    getAllAgents() {
      this.loading = true;
      getAgents().then(
        (res) => {
          this.loading = false;
          this.updateAgents(res.data);
        },
        (err) => {
          this.loading = false;
        }
      );
    },
  },

  computed: {
    ...mapGetters("admin", ["getAgentsDetails", "getStatistics"]),
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(59, 58, 58) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
.agent_table >>> tbody {
  cursor: pointer;
}
.empty_users {
  text-align: -webkit-center;
  height: 40vh;
  margin-top: 5rem;
}
.agent_details h4 {
  margin-top: 2px;
}
.agents p {
  color: rgb(165, 165, 165);
  font-size: 12px;
  font-weight: 500;
}
.savers_details {
  position: relative;
  height: 40vh;
  overflow-y: auto;
}
.card_left
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}

.insight_cards div {
  background-color: #f7f9fc;
  margin-right: 0.5rem;
  border-radius: 8px;
  padding: 10px;
  width: 30vw;
}

.insight_cards div > p {
  font-size: 12px;
}
.card_left {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.card_right {
  padding: 20px 20px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
  height: 30vh;
}
</style>