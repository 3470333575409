<template>
  <v-row>
    <v-col md="6" sm="12" cols="12">
      <v-card
        class="pa-4 card_left"
        :class="[$vuetify.breakpoint.smAndDown ? '' : 'ml-12']"
        :width="returnWidth"
      >
        <h4>Wallet</h4>
        <p>What wouldd you like to do today?</p>
        <BalanceCard />

        <div class="quick_actions">
          <p class="mt-6">QUICK ACTIONS</p>
          <AddSaver class="mb-2" />
          <PayBills class="mb-2" />
          <CreatePiggy class="mb-2" />
        </div>
      </v-card>
    </v-col>
    <v-col md="6" sm="12" cols="12">
      <v-card class="card_right pa-4" :width="returnWidth">
        <h5>Transactions</h5>

        <div class="btn_tabs">
          <v-btn
            color="primary"
            depressed
            :outlined="outlined('all')"
            @click.prevent="changeRoute('all')"
          >
            ALL</v-btn
          >
          <v-btn
            color="primary"
            depressed
            :outlined="outlined('credit')"
            @click.prevent="changeRoute('credit')"
            class="ml-2"
          >
            CREDIT</v-btn
          >
          <v-btn
            color="primary"
            depressed
            :outlined="outlined('debit')"
            @click.prevent="changeRoute('debit')"
            class="ml-2"
            >DEBIT</v-btn
          >
        </div>

        <v-text-field
          class="mt-8"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          solo
          label="Search"
        ></v-text-field>

        <!-- <div
          class="transactions"
          v-if="returnSearch.length > 0 || search !== ''"
        >
          <div
            v-for="(transaction, index) in paginatedFilteredTransaction"
            :key="index"
          >
            <v-row v-if="toggleTransaction(transaction)">
              <v-col md="6"
                ><div class="d-flex mt-n3">
                  <v-avatar
                    :color="transaction.type !== 'IN' ? '#E4F2FF' : '#D2FFE7'"
                    size="40"
                    ><h5>{{ transaction.type }}</h5></v-avatar
                  >
                  <div class="ml-2 mt-1">
                    <h5>{{ transaction.details }}</h5>
                    <p>{{ transformDate(transaction.time) }}</p>
                  </div>
                </div></v-col
              >
              <v-col md="6">
                <h5 class="text-right">
                  <v-icon color="primary" small> mdi-currency-ngn </v-icon
                  >{{ transaction.amount }}
                </h5></v-col
              >
            </v-row>
          </div>
        </div>
        <div class="transactions" v-else>
          <div
            v-for="(transaction, index) in paginatedTransaction"
            :key="index"
          >
            <v-row>
              <v-col md="6"
                ><div class="d-flex mt-n3">
                  <v-avatar
                    :color="transaction.type !== 'IN' ? '#E4F2FF' : '#D2FFE7'"
                    size="40"
                    ><h5>{{ transaction.type }}</h5></v-avatar
                  >
                  <div class="ml-2 mt-1">
                    <h5>{{ transaction.details }}</h5>
                    <p>{{ transformDate(transaction.time) }}</p>
                  </div>
                </div></v-col
              >
              <v-col md="6">
                <h5 class="text-right">
                  <v-icon color="primary" small> mdi-currency-ngn </v-icon
                  >{{ transaction.amount }}
                </h5></v-col
              >
            </v-row>
          </div>
        </div> -->
        <v-data-table
          :headers="transactionHeaders"
          :items="paginatedTransaction"
          :search="search"
          hide-default-footer
          hide-default-header
          :items-per-page="10"
          :page.sync="page"
          @page-count="pageCount = $event"
          class="transactionTable"
        >
          <template v-slot:[`item`]="{ item }">
            <v-row>
              <v-col md="6"
                ><div class="d-flex mt-n3">
                  <v-avatar
                    :color="item.type !== 'IN' ? '#E4F2FF' : '#D2FFE7'"
                    size="40"
                    ><h5>{{ item.type }}</h5></v-avatar
                  >
                  <div class="ml-2 mt-1">
                    <h5>{{ item.details }}</h5>
                    <p>{{ transformDate(item.time) }}</p>
                  </div>
                </div></v-col
              >
              <v-col md="6">
                <h5 class="text-right">
                  <v-icon color="primary" small> mdi-currency-ngn </v-icon
                  >{{ item.amount }}
                </h5></v-col
              >
            </v-row>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="pageCount"
          color="#960C0C"
          :total-visible="4"
        ></v-pagination>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BalanceCard from "../dashboard/balanceCard/WalletBalanceCard.vue";
import AddSaver from "../dashboard/quickActions/AddSaver";
import CreatePiggy from "../dashboard/quickActions/CreatePiggy.vue";
import PayBills from "../dashboard/quickActions/PayBills.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      page: 1,
      filteredSearch: [],
      pageCount: 0,
      loading: false,
      search: "",
      presentRoute: "all",
      transactionHeaders: [
        {
          text: "transaction",
          align: "start",
          sortable: false,
          value: "details",
        },
        { text: "amount", value: "amount" },
      ],
      transactions: [
        {
          type: "IN",
          details: "Wallet Funded",
          time: "10:00",
          amount: 20000,
          transaction: "credit",
        },
        {
          type: "OUT",
          details: "Airtel 08023342334",
          time: "10:00",
          amount: 40000,
          transaction: "debit",
        },
        {
          type: "IN",
          details: "Wallet Funded",
          time: "10:00",
          amount: 30000,
          transaction: "credit",
        },
        {
          type: "OUT",
          details: "Airtel 08023342334",
          time: "10:00",
          amount: 40000,
          transaction: "debit",
        },
        {
          type: "IN",
          details: "Wallet Funded",
          time: "10:00",
          amount: 60000,
          transaction: "credit",
        },
        {
          type: "OUT",
          details: "Airtel 08023342334",
          time: "10:00",
          amount: 40000,
          transaction: "debit",
        },
        {
          type: "IN",
          details: "Wallet Funded",
          time: "10:00",
          amount: 990000,
          transaction: "credit",
        },
        {
          type: "OUT",
          details: "Airtel 08023342334",
          time: "10:00",
          amount: 40000,
          transaction: "debit",
        },
      ],
    };
  },
  components: {
    BalanceCard,
    AddSaver,
    CreatePiggy,
    PayBills,
  },
  created() {
    this.getTransactions();
  },
  computed: {
    ...mapGetters("agent", ["agentTransactionsTransformed"]),
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "35vw";
      }
    },
    // pageCount() {
    //   if (this.filteredSearch.length > 0) {
    //     return Math.ceil(this.filteredSearch.length / 4);
    //   } else {
    //     return Math.ceil(this.agentTransactionsTransformed.length / 4);
    //   }
    // },
    paginatedTransaction() {
      let data = [...this.agentTransactionsTransformed];
      if (this.presentRoute === "all") {
        return data;
      } else if (this.presentRoute === "credit") {
        return data.filter(
          (transaction) => transaction.transaction === "credit"
        );
      } else {
        return data.filter(
          (transaction) => transaction.transaction === "debit"
        );
      }
    },
    paginatedFilteredTransaction() {
      let data = [...this.filteredSearch];
      return data.slice(this.start, this.end);
    },
    start() {
      return this.page * 4 - 4;
    },
    end() {
      return this.page * 4;
    },
    outlined() {
      return function (route) {
        if (this.presentRoute === route) {
          return false;
        } else {
          return true;
        }
      };
    },
    returnSearch() {
      if (this.search !== "") {
        let data = [...this.agentTransactionsTransformed];
        this.filteredSearch = data.filter((transaction) => {
          if (transaction.details !== null) {
            return (
              transaction.details
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            );
          } else {
            return transaction;
          }
        });
        return this.filteredSearch;
      } else {
        this.filteredSearch = [];
        return this.filteredSearch;
      }
    },
    toggleTransaction() {
      return function (transaction) {
        if (this.presentRoute === "all") {
          return true;
        } else if (this.presentRoute === transaction.transaction) {
          return true;
        }
        {
          return false;
        }
      };
    },
  },
  methods: {
    ...mapActions("agent", ["getAgentTransactions"]),
    changeRoute(type) {
      this.presentRoute = type;
    },
    transformDate(databasedate) {
      let date = new Date(databasedate);
      return date.toDateString();
    },
    getTransactions() {
      this.loading = true;
      let user = JSON.parse(sessionStorage.getItem("ajoData"));
      this.getAgentTransactions(user.user_id).then(
        (res) => {
          this.loading = false;
          console.log(res.data);
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    },
  },
};
</script>

<style scoped>
.transactionTable {
  max-height: 55vh;
  contain: content;
  overflow-y: auto;
}
.transactions {
  height: 60vh;
  overflow-y: auto;
}
.card_right p {
  font-size: 12px;
}
.col-12 {
  padding: 0 !important;
}
.card_right
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}
.card_left {
  position: relative;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
  height: 85vh;
}
.card_left p {
  font-size: 12px;
}
.card_right {
  position: relative;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.btn_tabs {
  margin-top: 5px;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(59, 58, 58) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
</style>