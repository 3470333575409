<template>
  <v-card :width="returnWidth" :height="returnHeight" class="px-8 pt-3 piggy">
    <v-row justify="end">
      <v-btn icon color="black" @click="closeModal" class="close_btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <div class="py-2 piggy_form">
      <div class="ml-3 header">
        <h5>Complete Saver's Profile</h5>

        <p class="mt-4">CREATE A PIGGY</p>
      </div>
      <v-row class="mt-4">
        <v-col md="6" sm="12" cols="12">
          <p>Name of Piggy</p>
          <v-text-field
            v-model="piggyName"
            outlined
            solo
            label="Enter Piggy Name"
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <p>Saving Frequency</p>
          <v-select
            v-model="savingFrequency"
            outlined
            :items="frequencies"
            solo
            label="Pick Saving Number"
            color="primary"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" sm="12" cols="12">
          <p>Amount</p>
          <v-text-field
            v-model="amount"
            type="number"
            class="number_input"
            outlined
            solo
            label="Enter Amount to Save"
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <p>Maturity Date</p>
          <v-menu
            v-model="dateDialog"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formatedDate"
                outlined
                type="date"
                solo
                label="Set a Maturity Date"
                color="primary"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              elevation="15"
              @input="dateDialog = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>Reason For Saving</p>
          <v-text-field
            v-model="piggyReason"
            outlined
            solo
            label="What is this Piggy For?"
            color="primary"
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="my-4">
        <v-row>
          <v-col md="3">
            <v-btn
              color="primary"
              large
              depressed
              outlined
              @click.prevent="() => back()"
            >
              BACK
            </v-btn>
          </v-col>
          <v-col md="3">
            <v-btn color="primary" large @click.prevent="() => addpiggy()">
              NEXT
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3">
            <h4>2/3</h4>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      dateDialog: false,
      loading: false,
      disabled: true,
      piggyName: "",
      amount: "",
      savingFrequency: "",
      piggyReason: "",
      date: "",
      frequencies: ["Yearly", "Monthly", "Weekly"],
    };
  },
  props: {
    dialog: Boolean,
  },
  computed: {
    ...mapGetters("customers", ["getSaverProfile"]),
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
    formatedDate() {
      return this.date;
    },
    returnHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "80vh";
      } else {
        return "80vh";
      }
    },
  },
  methods: {
    ...mapMutations("customers", ["saveUserProfile"]),
    closeModal() {
      this.$emit("closeModal");
    },
    cancel() {
      this.closeModal();
      this.fullname = "";

      this.phonenumber = "";
      this.relationship = "";
    },
    addpiggy() {
      let data = {
        piggy_name: this.piggyName,
        frequency: this.savingFrequency,
        target_amount: this.amount,
        description: this.piggyReason,
        maturity: this.date,
      };
      this.saveUserProfile(data);
      this.$emit("updateStep", 3);
    },
    back() {
      this.$emit("updateStep", 1);
    },
  },
};
</script>

<style scoped>
.number_input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.number_input >>> input::-webkit-outer-spin-button,
.number_input >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.piggy
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.piggy {
  contain: content;
  overflow-y: auto;
}
.piggy >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.piggy_form p {
  color: #272253;
  font-size: 13px;
  font-weight: 500;
}
.piggy_form >>> label,
input {
  font-size: 12px !important;
}

.header p {
  font-size: 10px;
  line-height: 18px;
}
</style>