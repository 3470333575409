<template>
  <div :class="[isMobile ? mobileView : webView]">
    <v-row>
      <v-col md="7" sm="12">
        <v-card class="pa-6 user" rounded="lg" flat>
          <p class="back_button" @click.prevent="() => goBack()">
            <v-icon small>mdi-chevron-left</v-icon> BACK TO SAVERS
          </p>
          <div class="d-flex mt-4">
            <v-avatar size="56" color="#EEECFF">
              <h4>
                {{
                  saver.first_name.split("")[0].toUpperCase() +
                  saver.last_name.split("")[0].toUpperCase()
                }}
              </h4>
            </v-avatar>

            <div class="ml-4 mt-1 user_details">
              <h4>
                {{ saver.first_name + " " + saver.last_name }}
              </h4>
              <p>{{ saver.phone }}</p>
            </div>
          </div>
          <div class="d-flex justify-space-between mt-4">
            <div class="mini_cards">
              <p>ACTIVE PIGGYS</p>
              <h4>{{ piggys.length }}</h4>
            </div>
            <div class="mini_cards">
              <p>BALANCE</p>
              <h4>{{ getSaverDetails.detail.balance }}</h4>
            </div>
          </div>
          <v-text-field
            class="mt-4"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            solo
            label="Search"
          ></v-text-field>
          <div class="transaction_header mt-6">
            <h5>TRANSACTION</h5>
          </div>
          <v-skeleton-loader type="list-item-avatar@2" :loading="loading">
            <div v-if="!(saverTransactions.length > 0)" class="mt-6">
              <v-img
                src="../../../assets/emptyUser.svg"
                width="80"
                height="80"
              ></v-img>
              <h5>No Transaction</h5>
              <h5>Made yet</h5>
            </div>
            <div class="transaction_body my-4" v-else>
              <div v-if="returnSearch.length > 0 || search !== ''">
                <div
                  class="d-flex my-4"
                  v-for="(transaction, index) in returnSearch"
                  :key="index"
                >
                  <v-avatar
                    :color="
                      transactionType(transaction.transaction_type)
                        ? '#CCCCFF'
                        : '#D2FFE7'
                    "
                    size="34"
                  >
                    <v-icon
                      :color="
                        transactionType(transaction.transaction_type)
                          ? '#1F51FF'
                          : '#13C7A6'
                      "
                      small
                    >
                      mdi-wallet
                    </v-icon>
                  </v-avatar>
                  <div class="transaction_details">
                    <h5>{{ transaction.title }}</h5>
                    <p>{{ transformDate(transaction.created) }}</p>
                  </div>
                  <div class="transaction_amount">
                    <h5>
                      <v-icon color="primary" small class="mb-1">
                        mdi-currency-ngn </v-icon
                      >{{ transaction.transaction_amount }}
                    </h5>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="d-flex my-4"
                  v-for="(transaction, index) in saverTransactions"
                  :key="index"
                >
                  <v-avatar
                    :color="
                      transactionType(transaction.transaction_type)
                        ? '#CCCCFF'
                        : '#D2FFE7'
                    "
                    size="34"
                  >
                    <v-icon
                      :color="
                        transactionType(transaction.transaction_type)
                          ? '#1F51FF'
                          : '#13C7A6'
                      "
                      small
                    >
                      mdi-wallet
                    </v-icon>
                  </v-avatar>
                  <div class="transaction_details">
                    <h5>{{ transaction.title }}</h5>
                    <p>{{ transformDate(transaction.created) }}</p>
                  </div>
                  <div class="transaction_amount">
                    <h5>
                      <v-icon color="primary" small class="mb-1">
                        mdi-currency-ngn </v-icon
                      >{{ transaction.transaction_amount }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col md="5" sm="12">
        <v-card class="view_piggy" flat>
          <div class="pa-6">
            <div class="d-flex justify-space-between">
              <p class="piggy_title">ALL PIGGYS</p>
              <v-btn color="primary" large @click.prevent="openCreateModal()"
                >CREATE PIGGY</v-btn
              >
            </div>
            <AddPiggy
              :saverDetails="getSaverDetails"
              @closeModal="closeCreateModal"
              :dialog="createDialog"
            />
            <v-skeleton-loader
              :loading="piggyLoading"
              type="avatar,card-heading"
            >
              <div v-if="piggys.length > 0">
                <div v-for="(piggy, index) in getPiggys" :key="index">
                  <div class="d-flex justify-space-between mt-4">
                    <div class="chip">
                      <PiggyImage :color="piggyColor" />
                    </div>
                    <div class="piggy_details mr-2">
                      <p>{{ piggy.title }}</p>
                      <h5 class="mt-1">
                        <v-icon color="primary" small> mdi-currency-ngn </v-icon
                        >{{ piggy.target_amount }}
                      </h5>
                      <span class="due_date">DUE IN 3 DAYS</span>
                      <div class="mt-1">
                        <v-btn
                          depressed
                          color="#FAFAFA"
                          light
                          @click.prevent="() => openPiggy(piggy)"
                          >VIEW PIGGY</v-btn
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <PiggySaver
                  @closemodal="closemodal"
                  :dialog="dialog"
                  :piggyDetails="piggy"
                  :saver="getSaverDetails"
                />
              </div>
              <div v-else>
                <h4>No Piggy Found</h4>
              </div>
            </v-skeleton-loader>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAllUserPiggy } from "../../services/api-services";
import PiggySaver from "./modal/piggy/Piggy";

import PiggyImage from "../../../assets/Piggy.vue";
import AddPiggy from "./modal/piggy/AddPiggy";
export default {
  data() {
    return {
      createDialog: false,
      piggy: {},
      search: "",
      loading: false,
      piggyLoading: false,
      piggys: [],
      dialog: false,
      colors: ["blue", "teal", "indigo"],
      presentIndex: 0,
      nextIndex: 0,
      mobileView: "mobileView",
      webView: "webView",
      filteredSearch: [],
    };
  },
  components: {
    PiggySaver,
    PiggyImage,
    AddPiggy,
  },
  created() {
    this.getTransactions();
    this.sendSaverToStore();
    this.getUserPiggy(this.$route.query.phone);
  },
  computed: {
    ...mapGetters("customers", [
      "saverTransactions",
      "getSaverDetails",
      "getPiggys",
    ]),
    returnSearch() {
      let data = [...this.saverTransactions];
      if (this.search !== "") {
        this.filteredSearch = data.filter(
          (transaction) =>
            transaction.title
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
        );
        console.log(this.filteredSearch);
        return this.filteredSearch;
      } else {
        this.filteredSearch = [];
        return this.filteredSearch;
      }
    },
    transactionType() {
      return function (type) {
        return type === "Utility";
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    piggyColor() {
      return this.colors[this.presentIndex];
    },
    saver() {
      return {
        first_name: this.getSaverDetails.detail.name.split(" ")[0],
        last_name: this.getSaverDetails.detail.name.split(" ")[1],
        phone: this.getSaverDetails.detail.phone,
      };
    },
  },
  methods: {
    ...mapActions("customers", ["getCustomerTransactions"]),
    ...mapMutations("customers", ["updateSaverDetails", "addPiggy"]),
    openPiggy(piggy) {
      this.piggy = piggy;
      this.dialog = true;
    },
    closemodal() {
      this.dialog = false;
    },
    closeCreateModal() {
      this.createDialog = false;
    },
    openCreateModal() {
      this.createDialog = true;
    },
    transformDate(databasedate) {
      let date = new Date(databasedate);
      return date.toDateString();
    },
    goBack() {
      this.$router.push("/agent/users");
    },
    getUserPiggy(data) {
      this.piggyLoading = true;
      getAllUserPiggy(data).then(
        (res) => {
          this.piggyLoading = false;
          this.addPiggy(res.data);
          this.piggys = res.data;
          console.log(res);
        },
        (err) => {
          this.piggyLoading = false;
          console.error(err);
        }
      );
    },
    getTransactions() {
      this.loading = true;
      let phone = this.$route.query.phone;
      this.getCustomerTransactions(phone).then(
        (res) => {
          this.loading = false;
          console.log(res.data);
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    },
    sendSaverToStore() {
      let saver = JSON.parse(sessionStorage.getItem("saverDetails"));
      this.updateSaverDetails(saver);
    },
  },
};
</script>

<style scoped>
.view_piggy {
  max-height: 85vh;
  contain: content;
  overflow-y: scroll;
}
.webView {
  padding: 10px 30px;
}
.mobileView {
  padding: unset;
}
.col {
  padding: unset !important;
}
.chip {
  padding: 5px 10px;
  background-color: #e8fcf1;
  border-radius: 5px;
  height: 50px;
}
.due_date {
  color: #959595;
  font-size: 11px;
  font-weight: 100;
}
.back_button {
  cursor: pointer;
  font-size: 12px !important;
}
.transaction_header {
  background-color: #f7f9fc;
  padding: 4px 4px;
}
.user_details p {
  font-size: 12px;
}
.piggy_title {
  font-size: 12px;
  color: grey;
}
.piggy_details p {
  font-size: 12px;
}
.mini_cards {
  background-color: #f7f9fc;
  border-radius: 2px;
  margin-right: 10px;
  width: 100%;
  padding: 10px 10px;
}
.mini_cards p {
  font-size: 12px;
}
.user
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 2px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(161, 161, 161) !important;
  border-radius: 2px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
.transaction_body {
  position: relative;
  height: 30vh;
  overflow-y: auto;
}
.bottom_card {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.transaction_header {
  background-color: #f7f9fc;
  padding: 4px 4px;
}
.transaction_header h5 {
  color: #272253;
}
.transaction_details {
  margin-left: 30px;
}
.transaction_details p {
  font-size: 12px;
}
.transaction_amount {
  position: absolute;
  right: 2rem;
}
</style>