var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"md":"9","sm":"12"}},[_c('v-card',{staticClass:"card_left",attrs:{"rounded":"lg"}},[_c('div',{staticClass:"mt-4 d-flex"},[_c('div',{attrs:{"md":"7","sm":"6"}},[_c('h4',{staticClass:"top_card_title"},[_vm._v("Stats")])])]),_c('div',{staticClass:"d-flex insight_cards mt-2"},[_c('div',{staticClass:"all_saver"},[_c('p',[_vm._v("ALL SAVERS")]),_c('h3',[_vm._v(_vm._s(_vm.getStatistics.all_savers))])]),_c('div',{staticClass:"active_saver"},[_c('p',[_vm._v("ALL AGENTS")]),_c('h3',[_vm._v(_vm._s(_vm.getStatistics.no_of_agents))])]),_c('div',{staticClass:"active_saver"},[_c('p',[_vm._v("ALL TRANSACTIONS")]),_c('h4',[_vm._v("50000")])])]),(!(_vm.transactions.length > 0))?_c('div',{staticClass:"my-6"},[_c('h5',[_vm._v("No Transaction")])]):_c('div',[_c('v-text-field',{staticClass:"mt-4",attrs:{"prepend-inner-icon":"mdi-magnify","solo":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"agents md-px-6"},[_c('h5',{staticClass:"mt-4"},[_vm._v("Transactions")]),_c('v-data-table',{staticClass:"elevation-1 agent_table",attrs:{"headers":_vm.headers,"items":_vm.transactions,"single-select":_vm.singleSelect,"search":_vm.search,"item-key":"agent","show-select":"","loading":_vm.loading,"mobile-breakpoint":"0"},on:{"click:row":function (agent) { return _vm.agentDetails(agent); }},scopedSlots:_vm._u([{key:"item.agent",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex agent_details"},[_c('v-avatar',{attrs:{"color":"#EEECFF","size":"40"}},[_c('h5',[_vm._v(" AG ")])]),_c('div',{staticClass:"ml-2"},[_c('h4',[_vm._v("Agent")]),_c('p',[_vm._v(_vm._s(item.phone))])])],1)]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('h5',[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-currency-ngn ")]),_vm._v(_vm._s(item.balance)+" ")],1)]}},{key:"item.savers",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(item.savers)+" ")])]}},{key:"item.last_transaction",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.formatDate(item.last_transaction))+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)])],1),_c('v-col',{attrs:{"md":"3","sm":"12"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }