<template>
  <svg
    width="38"
    height="32"
    viewBox="0 0 38 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5409 32C24.6291 32 22.9983 30.7923 22.3931 29.1106H14.4122C13.026 32.9402 7.50131 32.9378 6.11664 29.1106H5.86538C2.63282 29.1106 0.00256611 26.5207 0 23.3363V23.3341V15.3138C0.0097512 10.9111 1.10526 7.01862 4.27638 4.16607C7.41898 1.36271 12.1836 0 18.8425 0C22.3643 0 25.2995 1.95265 27.3307 5.64688C28.3558 7.5114 28.9361 9.4348 29.2355 10.6717C31.2202 11.0712 32.9602 11.684 34.5305 12.5344C36.3859 13.5392 37.5385 15.473 37.5385 17.5811V23.3318C37.5385 26.5182 34.9072 29.1106 31.6731 29.1106H30.6886C30.0834 30.7923 28.4526 32 26.5409 32ZM13.1971 26.2212H23.6082C24.418 26.2212 25.0745 26.868 25.0745 27.6659C25.155 29.5825 27.927 29.5819 28.0072 27.6659C28.0072 26.868 28.6637 26.2212 29.4736 26.2212H31.6731C33.2902 26.2212 34.6058 24.925 34.6058 23.3318V17.5811C34.6058 16.5271 34.0357 15.5635 33.1178 15.0664C31.5893 14.2387 29.8408 13.6755 27.7724 13.3449C27.0987 13.2494 26.6224 12.6627 26.5544 12.1275C26.2584 10.5848 24.4964 2.88939 18.8425 2.88939C4.74106 2.88939 2.93269 9.52921 2.93269 15.3138V23.3318C2.93269 24.925 4.2483 26.2212 5.86538 26.2212H7.33173C8.14159 26.2212 8.79808 26.868 8.79808 27.6659C8.87865 29.5825 11.6506 29.5819 11.7308 27.6659C11.7308 26.868 12.3873 26.2212 13.1971 26.2212ZM20.8954 16.3973C20.3801 16.3973 19.9269 16.6592 19.6654 17.0553C17.3371 20.2114 13.4284 20.1809 11.1272 17.0543C10.8656 16.6588 10.4127 16.3973 9.89784 16.3973C8.72923 16.3856 8.02253 17.7214 8.68641 18.6562C12.1166 23.5009 18.6579 23.5151 22.102 18.6632C22.7737 17.7288 22.0678 16.3856 20.8954 16.3973ZM12.9772 11.7743C12.9772 12.7716 12.1565 13.5801 11.1442 13.5801C8.71288 13.485 8.71354 10.0632 11.1442 9.9684C12.1565 9.9684 12.9772 10.7769 12.9772 11.7743ZM19.649 9.9684C18.6367 9.9684 17.8161 10.7769 17.8161 11.7743C17.9127 14.1697 21.3858 14.1691 21.482 11.7743C21.482 10.7769 20.6613 9.9684 19.649 9.9684Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    color: String,
  },
};
</script>

<style>
</style>