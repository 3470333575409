<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-row justify="end">
          <v-btn icon color="black" @click="closeModal" class="close_btn mt-4">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <div class="px-6 py-6">
          <h5>Confirm Details</h5>

          <div class="d-flex mt-4 saver_details">
            <div class="image_background">
              <Piggy :color="color" />
            </div>
            <div class="ml-8">
              <h4>{{ piggyDetails.description }}</h4>
              <p>by {{ saver.detail.name }}</p>
            </div>
          </div>
          <div class="sub_details justify-space-between d-flex mt-4">
            <div>
              <p>BALANCE</p>
              <h4>{{ piggyDetails.piggy_balance }}</h4>
            </div>
            <div>
              <p>TYPE</p>
              <h4>{{ piggyDetails.saving_frequency }}</h4>
            </div>
          </div>
          <div class="more_details justify-space-between d-flex mt-4">
            <div>
              <p>START DATE</p>
              <h5>Aug 4, 2020</h5>
            </div>
            <div>
              <p>MATURITY DATE</p>
              <h5>{{ piggyDetails.maturity_date }}</h5>
            </div>
          </div>
          <div class="actions d-flex mt-4">
            <v-btn
              color="primary"
              outlined
              @click.prevent="() => openTransaction('withdraw')"
            >
              WITHDRAW SAVINGS
            </v-btn>
            <v-btn
              color="primary"
              class="ml-4"
              @click.prevent="() => openTransaction('topup')"
            >
              TOP UP PIGGY
            </v-btn>
          </div>
        </div>
        <PiggyTransaction
          ref="piggy"
          :transactionType="transactionType"
          :piggyDetails="piggyDetails"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Piggy from "../../../../../assets/Piggy.vue";
import PiggyTransaction from "./PiggyTransaction.vue";
export default {
  data() {
    return {
      color: "blue",
      transactionType: "",
    };
  },
  props: {
    dialog: Boolean,
    piggyDetails: Object,
    saver: Object,
  },

  components: {
    Piggy,
    PiggyTransaction,
  },

  methods: {
    closeModal() {
      this.$emit("closemodal");
    },
    openTransaction(type) {
      this.transactionType = type;
      this.closeModal();
      this.$refs.piggy.$el.nextSibling.click();
    },
  },
};
</script>
<style scoped>
.image_background {
  padding: 5px 5px;
  background-color: rgb(224, 255, 245);
}
.saver_details p {
  font-size: 12px;
}
.saver_details h4 {
  font-size: 18px !important;
}
.sub_details {
  padding: 20px 40px;
  background-color: #eeecff;
  border-radius: 5px;
}
.more_details {
  padding: 20px 40px;
}
.actions {
  padding: 20px 10px;
}
.sub_details,
.more_details p {
  font-size: 12px;
}
</style>