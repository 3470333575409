<template>
  <div>
    <v-btn color="primary" @click.prevent="viewKYC"> VIEW KYC </v-btn>
    <v-dialog v-model="dialog" persistent :width="returnWidth">
      <v-card :width="returnWidth" class="kyc-container" :loading="loading">
        <v-row justify="end">
          <v-btn icon color="black" @click="() => closeModal()" class="mt-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <h4>KYC Details</h4>
        <p class="sub-title">See agent's CAC Document & ID Card</p>

        <div v-if="!isKYC && !loading">
          <p>No Document</p>
        </div>
        <div v-else-if="isKYC && !loading">
          <div v-for="(key, index) in KYC" :key="index" class="mt-4">
            <span class="doc-title">{{
              key === "cac_certificate" ? "CAC Document" : "ID Card"
            }}</span>
            <div class="d-flex">
              <a :href="kyc[key]" target="_blank" download class="download_btn"
                >download</a
              >
              <embed
                :src="kyc[key]"
                width="300"
                height="100"
                class="ml-4 view_document"
              />
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getKYC } from "../../services/api-services";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      kyc: {},
    };
  },
  methods: {
    viewKYC() {
      this.dialog = true;
      this.loading = true;
      let number = this.$route.query.phone;
      getKYC(number)
        .then((res) => {
          this.kyc = res.data;
          console.log(res);
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
      console.log(number);
    },
    closeModal() {
      this.dialog = false;
    },
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
    isKYC() {
      return Object.keys(this.kyc).length > 0;
    },
    KYC() {
      return Object.keys(this.kyc);
    },
  },
};
</script>

<style scoped>
.view_document {
  overflow: visible;
}
.kyc-container {
  min-height: 5vh;
  padding-left: 20px;
  padding-bottom: 20px;
}
.doc-title {
  font-size: 12px;
  color: #272253;
  font-weight: 600;
}
.sub-title {
  font-size: 12px;
  color: #272253;
}
.download_btn {
  text-decoration: unset !important;
  text-transform: uppercase;
  background-color: #272253;
  color: white;
  height: fit-content;
  padding: 10px;
  font-size: 12px;
  border-radius: 4px;
}
</style>