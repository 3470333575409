<template>
  <v-card
    class="px-4 mt-12 mx-auto profile-container"
    :width="returnWidth"
    flat
  >
    <h4>Welcome to your Profile</h4>
    <div class="d-flex justify-space-between mt-4">
      <div>
        <span class="profile-title">Profile Picture</span>
        <p class="profile-subtitle">Avatar presently used as profile picture</p>
      </div>

      <div>
        <v-avatar>
          <img :src="agent.image" :alt="agent.first_name" />
        </v-avatar>
      </div>
    </div>
    <div class="d-md-flex justify-space-between mt-4">
      <div>
        <span class="profile-title">CAC</span>
        <p class="profile-subtitle">View and Update CAC Documents</p>
      </div>

      <div class="view-documents">
        <v-btn color="primary" @click="() => getFile()" outlined
          >UPLOAD KYC</v-btn
        >
        <input
          ref="fileInput"
          accept="application/pdf,application/vnd.ms-excel"
          type="file"
          @change="onChange"
          style="display: none"
        />
        <div v-if="image_src !== ''" class="mt-8">
          <embed :src="image_src" width="300" height="100" class="mt-4" />
          <div v-if="files.length > 0">
            <h5>{{ files[0].name }}</h5>
            <p>{{ calculateFileSize(files[0].size) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-flex justify-space-between mt-4">
      <div>
        <span class="profile-title">Id Card</span>
        <p class="profile-subtitle">View and Update Valid Identity Card</p>
      </div>

      <div class="view-documents">
        <v-btn color="primary" @click="() => getID()" outlined
          >UPLOAD ID CARD</v-btn
        >
        <input
          ref="idInput"
          accept="application/pdf,application/vnd.ms-excel"
          type="file"
          @change="onIdChange"
          style="display: none"
        />
        <div v-if="image_id_src !== ''" class="mt-8">
          <embed :src="image_id_src" width="300" height="100" class="mt-4" />
          <div v-if="files_id.length > 0">
            <h5>{{ files_id[0].name }}</h5>
            <p>{{ calculateFileSize(files_id[0].size) }}</p>
          </div>
        </div>
        <div
          class="d-md-flex justify-space-between mt-4"
          v-if="files.length > 0 || files_id.length > 0"
        >
          <v-btn
            color="primary"
            outlined
            class="mt-4"
            large
            @click.prevent="cancelUpload"
          >
            CANCEL</v-btn
          >
          <v-btn
            color="primary"
            class="mt-4"
            large
            :loading="loading"
            @click.prevent="uploadKYCDocument"
          >
            SAVE CHANGES</v-btn
          >
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { uploadKYC, updateKYC } from "../../services/api-services";
export default {
  data() {
    return {
      name: "Eromo",
      image_src: "",
      image_id_src: "",
      files: [],
      files_id: [],
      loading: false,
    };
  },
  mounted() {
    console.log(this.agent);
  },
  methods: {
    cancelUpload() {
      this.image_src = "";
      this.image_id_src = "";
      this.files = [];
      this.files_id = [];
    },
    onChange(event) {
      event.preventDefault();
      if (event.target.files[0].size <= 100000) {
        this.files = event.target.files;
        this.image_src = URL.createObjectURL(event.target.files[0]);
      } else {
        this.$store.commit("snackbar/SHOW_SNACKBAR", {
          message: "File Size Exceeds 1MB",
          success: false,
        });
      }
    },
    onIdChange(event) {
      event.preventDefault();
      if (event.target.files[0].size <= 100000) {
        this.files_id = event.target.files;
        this.image_id_src = URL.createObjectURL(event.target.files[0]);
      } else {
        this.$store.commit("snackbar/SHOW_SNACKBAR", {
          message: "File Size Exceeds 1MB",
          success: false,
        });
      }
    },
    calculateFileSize(size) {
      return size / 100000 + "mb";
    },
    getFile() {
      this.$refs.fileInput.click();
    },
    getID() {
      this.$refs.idInput.click();
    },
    uploadKYCDocument() {
      this.loading = true;
      const data = new FormData();

      if (this.files.length > 0) {
        data.append("cac", this.files[0]);
        data.append("id", this.files_id[0]);
      }

      updateKYC(data)
        .then((res) => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Upload Successful",
            success: true,
          });
          console.log(res);
        })
        .catch((err) => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Upload Unsuccessful",
            success: false,
          });
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.files = [];
        });
    },
  },
  computed: {
    ...mapGetters("agent", ["agent"]),

    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
  },
};
</script>

<style scoped>
.view-documents {
  width: 300px;
  text-align: end;
}
.profile-container h4 {
  text-align: center;
}
.col {
  padding: 12px 0 !important;
}
.right-coloumn {
  position: relative;
  left: 5rem;
}
.profile-title {
  font-size: 14px;
  font-weight: 400;
  color: #272253;
}
.profile-subtitle {
  font-size: 12px;
  font-weight: 400;
  color: rgb(150, 148, 148);
}
.view_image {
  border-radius: 5px;
}
</style>