<template>
  <v-row>
    <v-col md="9" sm="12">
      <v-card class="card_left" rounded="lg">
        <div class="mt-4 d-flex">
          <div md="7" sm="6">
            <h4 class="top_card_title">Savers</h4>
          </div>
          <v-spacer></v-spacer>
          <div md="2" sm="6">
            <AddSaver :open="open">
              <template v-slot:button>
                <v-btn
                  color="primary btn_header mr-2"
                  @click.prevent="openModal()"
                  >+ ADD SAVER</v-btn
                >
              </template>
            </AddSaver>
          </div>
        </div>

        <div class="d-flex insight_cards mt-2">
          <div class="all_saver">
            <p>ALL SAVERS</p>
            <h3>{{ getSavers.length }}</h3>
          </div>
          <div class="active_saver">
            <p>ALL SAVERS</p>
            <h3>1</h3>
          </div>
          <div class="active_saver">
            <p>ACTIVE PIGGY</p>
            <h3>1</h3>
          </div>
        </div>
        <v-text-field
          class="mt-4"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          solo
          label="Search"
        ></v-text-field>
        <v-divider class="my-4"></v-divider>
        <div class="savers px-6">
          <!-- <v-row>
            <v-col md="1"><p>#</p></v-col>
            <v-col md="6"><p>SAVER</p></v-col>
            <v-col md="2"><p class="text-right">ACTIVE PIGGY</p></v-col>
            <v-col md="2"><p class="text-right">BALANCE</p></v-col>
          </v-row> -->
          <v-skeleton-loader type="list-item-avatar@2" :loading="loading">
            <div v-if="savers.length > 0">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="savers"
                :single-select="singleSelect"
                :search="search"
                :custom-filter="
                  (value, search, item) => searchFilter(value, search, item)
                "
                item-key="phone"
                show-select
                :loading="loading"
                class="elevation-1 agent_table"
                mobile-breakpoint="0"
                @click:row="(saver) => saverDetails(saver)"
              >
                <template v-slot:[`item.saver`]="{ item }">
                  <div class="d-flex agent_details">
                    <v-avatar color="#EEECFF" size="40">
                      <h5>
                        {{
                          returnIntials(item.first_name + " " + item.last_name)
                        }}
                      </h5>
                    </v-avatar>
                    <div class="ml-2">
                      <h4>{{ item.first_name }} {{ item.last_name }}</h4>

                      <p>{{ item.phone }}</p>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.balance`]="{ item }">
                  <h5>
                    <v-icon color="primary" small> mdi-currency-ngn </v-icon
                    >{{ item.balance }}
                  </h5>
                </template>
                <template v-slot:[`item.savers`]="{ item }">
                  <h5>
                    {{ item.savers }}
                  </h5>
                </template>
                <template v-slot:[`item.last_transaction`]="{ item }">
                  <h5>
                    {{ formatDate(item.last_transaction) }}
                  </h5>
                </template>
                <template v-slot:[`item.created`]="{ item }">
                  <h5>
                    {{ formatDate(item.created) }}
                  </h5>
                </template>
              </v-data-table>
            </div>
            <div v-else class="empty_users">
              <v-img
                src="../../../assets/emptyUser.svg"
                width="80"
                height="80"
              ></v-img>
              <h5>You haven't added any</h5>
              <h5>Savers yet</h5>
            </div>
          </v-skeleton-loader>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CreatePiggy from "@/agent/components/dashboard/quickActions/CreatePiggy.vue";
import MakePayment from "@/agent/components/dashboard/quickActions/PayBills.vue";
import AddSaver from "@/agent/components/savers/AddSaver";
import { getAllSavers } from "../../services/api-services";
export default {
  data() {
    return {
      loading: false,
      open: false,
      selected: [],
      step: 1,
      addSaverModal: false,
      verifySaverModal: false,
      profileModal: false,
      singleSelect: false,
      page: 1,
      headers: [
        {
          text: "SAVER",
          align: "start",
          sortable: false,
          value: "saver",
        },
        { text: "AGENT", value: "agent" },
        { text: "ACTIVE PIGGY", value: "active_piggy" },
        { text: "BALANCE", value: "balance" },
        { text: "JOINED", value: "created" },
      ],
      search: "",
    };
  },
  components: {
    CreatePiggy,
    MakePayment,
    AddSaver,
  },
  methods: {
    ...mapMutations("savers", ["updateSavers"]),

    returnIntials(user) {
      let stringArray = user.split(" ");
      let firstInitial = stringArray[0].substring(0, 1);
      let secondInitial = stringArray[1].substring(0, 1);
      return firstInitial.toUpperCase() + secondInitial.toUpperCase();
    },

    formatDate(date) {
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let month = newDate.getMonth() + 1;
      let dt = newDate.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      return dt + "/" + month + "/" + year;
    },
    saverDetails(saver) {
      sessionStorage.setItem("saverDetails", JSON.stringify(saver));
      this.$router.push(`/admin/saver/${saver.id}/?phone=${saver.phone}`);
    },
    returnSaver() {
      this.loading = true;
      getAllSavers().then(
        (res) => {
          this.updateSavers(res.data);
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
    },
  },
  created() {
    if (!(this.getSavers.length > 0)) {
      this.returnSaver();
    }
  },

  computed: {
    ...mapGetters("savers", ["getSavers"]),
    savers() {
      return this.getSavers;
    },
    returnProfile() {
      if (this.step === 1) {
        return NextOfKin;
      } else if (this.step === 2) {
        return CreateAPiggy;
      } else if (this.step === 3) {
        return Details;
      }
    },
    returnSearch() {
      if (this.search !== "") {
        this.filteredSearch = this.savers.filter(
          (saver) =>
            saver.detail.name
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
        );
        return this.filteredSearch;
      } else {
        this.filteredSearch = [];
        return this.filteredSearch;
      }
    },

    paginatedSavers() {
      let data = [...this.savers];
      return data.slice(this.start, this.end);
    },
    pageCount() {
      if (this.filteredSearch.length > 0) {
        return Math.ceil(this.filteredSearch.length / 4);
      } else {
        return Math.ceil(this.savers.length / 4);
      }
    },
    paginatedFilteredSavers() {
      let data = [...this.filteredSearch];
      return data.slice(this.start, this.end);
    },
    start() {
      let a = this.page * 4;
      let b = 4;
      return a - b;
    },
    end() {
      return this.page * 4;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(59, 58, 58) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
.agent_details {
  cursor: pointer;
}
.saver_row {
  cursor: pointer;
}
.empty_users {
  text-align: -webkit-center;
  height: 40vh;
  margin-top: 5rem;
}

.savers p {
  color: rgb(165, 165, 165);
  font-size: 12px;
  font-weight: 500;
}
.savers_details {
  position: relative;
  height: 40vh;
  overflow-y: auto;
}
.card_left
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}

.insight_cards div {
  background-color: #f7f9fc;
  margin-right: 0.5rem;
  border-radius: 8px;
  padding: 10px;
  width: 30vw;
}

.insight_cards div > p {
  font-size: 12px;
}
.card_left {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.card_right {
  padding: 20px 20px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
  height: 30vh;
}
</style>