<template>
  <div>
    <v-dialog v-model="dialog" persistent :width="returnWidth">
      <v-card class="pb-4 cable">
        <v-row>
          <v-col md="5" class="mt-2">
            <div class="d-flex">
              <div class="image_background">
                <img src="@/assets/airtime.svg" height="20" />
              </div>

              <span class="mx-2 mt-1">
                <h5>SELECT CABLE TV</h5>
              </span>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3">
            <v-btn icon color="black" @click="closeModal" class="close_btn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mb-1"></v-divider>
        <div class="cable_form mx-auto pa-6">
          <h5>SELECT NETWORK</h5>
          <v-row>
            <v-col md="3" v-for="(provider, index) in TVProviders" :key="index">
              <div
                @click.prevent="() => selectProvider(provider.name)"
                :class="[
                  selectedProvider === provider.name
                    ? 'providerCardSelected'
                    : 'providerCard',
                ]"
              >
                <img :src="provider.imgSrc" height="40" contain />
              </div>
            </v-col>
          </v-row>

          <div class="cable_data_form">
            <ValidationObserver>
              <v-form ref="form" @submit.prevent="buyCable">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="data plan"
                  v-if="isData"
                >
                  <h5>Package</h5>
                  <v-select
                    v-model="cablePackage"
                    solo
                    outlined
                    label="Select a plan"
                    :items="getTVSubscriptions[selectedProvider]"
                    :loading="TVSubscriptionLoading"
                    :disabled="TVSubscriptionLoading"
                    item-text="name"
                    return-object
                    color="primary"
                  ></v-select>
                  <span id="error" v-if="false">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="amount"
                >
                  <h5>Cable Number</h5>
                  <v-text-field
                    v-model="cableNumber"
                    outlined
                    solo
                    label="Enter Amount"
                    color="primary"
                  ></v-text-field>
                  <span id="error" v-if="false">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="phonenumber"
                >
                  <h5>Phone Number</h5>
                  <v-text-field
                    v-model="phoneNumber"
                    outlined
                    solo
                    label="Enter Phone Number"
                    color="primary"
                  ></v-text-field>
                  <span id="error" v-if="false">{{ errors[0] }}</span>
                </ValidationProvider>

                <v-btn
                  color="primary"
                  type="submit"
                  class="mt-8"
                  large
                  :loading="loading"
                  width="100%"
                >
                  BUY
                </v-btn>
              </v-form>
            </ValidationObserver>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <TransactionPin
      :dialog="cableDialog"
      :transaction="transaction"
      :transact="transact"
      @closeModal="closePinModal"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import TransactionPin from "./TransactionPin";
import { cableTransaction } from "@/services/api-services";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TransactionPin,
  },
  data() {
    return {
      transaction: {},
      cableDialog: false,
      isData: true,
      loading: false,
      model: 0,
      TVSubscriptionLoading: false,
      providerCard: "providerCard",
      providerCardSelected: "providerCardSelected",
      cableNumber: "",
      cablePackage: "",
      cablePackages: ["Night Plan", "Morning Plan"],
      selectedProvider: "",
      phoneNumber: "",
      TVProviders: [
        {
          name: "dstv",
          imgSrc: require("../../../../assets/dstv.svg"),
        },
        {
          name: "gotv",
          imgSrc: require("../../../../assets/gotv.svg"),
        },
        {
          name: "startimes",
          imgSrc: require("../../../../assets/actv.svg"),
        },
        {
          name: "linda",
          imgSrc: require("../../../../assets/linda.svg"),
        },
      ],
    };
  },
  watch: {
    model(newValue, oldvalue) {
      if (newValue === 0) {
        this.isData = true;
      } else {
        this.isData = false;
      }
    },
    selectedProvider(newValue, oldvalue) {
      if (newValue !== oldvalue && newValue !== "") {
        if (!(this.getTVSubscriptions[newValue].length > 0)) {
          this.loadTVSubscriptions(newValue);
        }
      }
    },
  },
  props: {
    dialog: Boolean,
  },
  methods: {
    ...mapActions("payments", ["TVSubscriptionPlans"]),
    closeModal() {
      this.$emit("closeModal");
      this.selectedProvider = "";
      this.cablePackage = "";
      this.cableNumber = "";
      this.phoneNumber = "";
    },

    rechargeCable() {
      console.log("hi");
    },
    selectProvider(provider) {
      if (this.selectedProvider === provider) {
        this.selectedProvider = "";
      } else {
        this.selectedProvider = provider;
      }
    },
    buyCable() {
      this.transaction = {
        service_id: `${this.selectedProvider + "-data"}`,
        amount: this.cablePackage.variation_amount,
        phone_no: this.phoneNumber,
        billersCode: "08139156449",
        variation_code: this.cablePackage.variation_code,
      };

      this.cableDialog = true;
      this.closeModal();
    },
    closePinModal() {
      this.cableDialog = false;
    },
    transact(data) {
      return cableTransaction(data).then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    loadTVSubscriptions(provider) {
      this.TVSubscriptionLoading = true;
      this.TVSubscriptionPlans(provider).then(
        (res) => {
          this.TVSubscriptionLoading = false;
        },
        (err) => {
          this.TVSubscriptionLoading = false;
        }
      );
    },
  },

  computed: {
    ...mapGetters("payments", ["getTVSubscriptions"]),
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
    buttonDisable() {
      if (
        this.cablePackage !== "" &&
        this.cableNumber !== "" &&
        this.phoneNumber !== "" &&
        this.selectedProvider !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.cable {
  contain: content;
}
.cable_data_form {
  margin-top: 12px;
  padding: 10px 5px;
}
.cable_data_form h5 {
  font-size: 10px;
  text-align: left;
  font-weight: 600;
}
.cable_form h5 {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}
.providerCard {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 0;
  box-shadow: 0px 11px 20px rgba(130, 130, 130, 0.15) !important ;
}
.providerCardSelected {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 0;
  box-shadow: 0px 11px 20px rgba(130, 130, 130, 0.15) !important ;
  border: 0.5px solid #272253;
}
.airtime_carousel >>> button {
  background-color: white;
}
.airtime_carousel >>> button :hover {
  background-color: #d0ddff;
}
.airtime_carousel >>> i {
  color: #272253 !important;
}
.airtime_carousel >>> .v-responsive__content {
  margin-top: 4px;
}
.airtime_carousel {
  position: relative;
}
.cable_data_form span {
  font-size: 10px;
}
.cable_data_form
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.cable_data_form
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.cable_data_form >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.cable_data_form >>> label {
  font-size: 12px;
}
.cable_data_form >>> .v-select__selection {
  font-size: 13px;
  color: #272253 !important;
  text-transform: uppercase;
  font-weight: 600;
}
.close_btn {
  position: absolute;
  right: 10px;
}
.image_background {
  background-color: #f4e8fa;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
  text-align: center;
  width: 35px;
}
.cable_form {
  padding: 0 50px;
  text-align: center;
}
</style>