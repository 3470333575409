<template>
  <div>
    <slot name="button"> </slot>
    <v-dialog v-model="dialog" persistent :width="returnWidth">
      <v-card :width="returnWidth">
        <v-row justify="end">
          <v-btn icon color="black" @click="closeModal" class="close_btn mt-4">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    open: Boolean,
  },
  watch: {
    open: function (newValue, oldValue) {
      if (newValue) {
        this.dialog = true;
      } else if (!newValue) {
        this.dialog = true;
      }
    },
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>