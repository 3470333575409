<template>
  <v-dialog v-model="dialog" persistent :width="returnWidth">
    <v-card class="pb-4 electricity">
      <v-row>
        <v-col md="5" class="mt-2">
          <div class="d-flex">
            <div class="image_background">
              <img src="@/assets/electricity.svg" height="20" />
            </div>

            <span class="mx-2 mt-1">
              <h5>Buy Electricity</h5>
            </span>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3">
          <v-btn icon color="black" @click="closeModal" class="close_btn">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="mb-1"></v-divider>

      <div class="electricity_form mx-auto pa-6">
        <h5>Electricity Provider</h5>
        <v-select
          v-model="electricityProvider"
          solo
          flat
          label="Select Provider"
          :items="electricityProviders"
          color="primary"
        ></v-select>
        <v-carousel
          v-model="model"
          hide-delimiter-background
          hide-delimiters
          height="25"
          class="electricity_carousel"
        >
          <v-carousel-item v-for="(item, index) in items" :key="index">
            <h4>{{ item }}</h4>
          </v-carousel-item>
        </v-carousel>
        <div class="electricity_data_form">
          <ValidationObserver>
            <v-form ref="form" @submit.prevent="getElectricity">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="meter number"
              >
                <h5>Meter Number</h5>
                <v-text-field
                  v-model="meterNumber"
                  solo
                  outlined
                  label="Enter Meter Number"
                  color="primary"
                ></v-text-field>
                <span id="error" v-if="false">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="amount"
              >
                <h5>Amount</h5>
                <v-text-field
                  v-model="amount"
                  outlined
                  solo
                  label="Enter Amount"
                  color="primary"
                ></v-text-field>
                <span id="error" v-if="false">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="phonenumber"
              >
                <h5>Phone Number</h5>
                <v-text-field
                  v-model="phoneNumber"
                  outlined
                  solo
                  label="Enter Phone Number"
                  color="primary"
                ></v-text-field>
                <span id="error" v-if="false">{{ errors[0] }}</span>
              </ValidationProvider>

              <v-btn
                color="primary"
                type="submit"
                class="mt-8"
                depressed
                :loading="loading"
                width="100%"
              >
                BUY
              </v-btn>
            </v-form>
          </ValidationObserver>
        </div>
        <TransactionPin
          :dialog="pinDialog"
          :transaction="transaction"
          :transact="transact"
          @closeModal="closePinModal"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { buyElectricity } from "@/services/api-services";
import TransactionPin from "./TransactionPin";
export default {
  data() {
    return {
      pinDialog: false,
      transaction: {},
      model: 0,
      electricityProvider: "",
      electricityProviders: ["EKEDC", "IKJDC"],
      items: ["PREPAID", "POSTPPAID"],
      meterNumber: "",
      amount: "",
      phoneNumber: "",
      loading: false,
    };
  },
  props: {
    dialog: Boolean,
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    getElectricity() {
      if (this.electricityProvider === "IKJDC") {
        this.transaction = {
          service_id: "ikeja-electric",
          amount: this.amount,
          pin: "1251",
          phone_no: this.phoneNumber,
          billersCode: "1111111111111",
          variation_code: "prepaid",
          card_type: "prepaid",
        };
      } else {
        this.transaction = {
          service_id: "eko-electric",
          amount: this.amount,
          pin: "1251",
          phone_no: this.phoneNumber,
          billersCode: "1111111111111",
          variation_code: "prepaid",
        };
      }

      this.pinDialog = true;
      this.closeModal();
    },
    transact(data) {
      return buyElectricity(data).then(
        (res) => {
          this.loading = false;
          console.log(res);
          return Promise.resolve(res);
        },
        (err) => {
          this.loading = false;
          console.log(err);
          return Promise.reject(err);
        }
      );
    },
    closePinModal() {
      this.pinDialog = false;
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    TransactionPin,
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
  },
};
</script>

<style scoped>
.electricity {
  contain: content;
}
.electricity_data_form {
  margin-top: 12px;
  padding: 10px 5px;
}
.electricity_data_form h5 {
  font-size: 10px;
  text-align: left;
  font-weight: 500;
}
.electricity_form h5 {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}
.electricity_form h4 {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center;
}
.electricity_carousel >>> button {
  background-color: white;
}
.electricity_carousel >>> button :hover {
  background-color: #d0ddff;
}
.electricity_carousel >>> i {
  color: #272253 !important;
}
.electricity_carousel >>> .v-responsive__content {
  margin-top: 4px;
}
.electricity_carousel {
  position: relative;
}
.electricity_data_form span {
  font-size: 10px;
}
.electricity_data_form
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  width: 100%;
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.electricity_data_form
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  width: 100%;
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.electricity_data_form >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.electricity_data_form >>> label {
  font-size: 12px;
}
.electricity_data_form >>> .v-select__selection {
  font-size: 13px;
  color: #272253 !important;
  text-transform: uppercase;
  font-weight: 600;
}
.close_btn {
  position: absolute;
  right: 10px;
}
.image_background {
  background-color: #f4e8fa;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
  text-align: center;
  width: 35px;
}
.electricity_form {
  padding: 0 50px;
  text-align: center;
}
</style>