<template>
  <div>
    <v-card
      :color="color"
      :elevation="2"
      class="add_saver"
      @click.prevent="openModal"
    >
      <h5>Create Piggy</h5>
      <img
        src="../../../../assets/piggy.svg"
        alt=""
        height="30"
        class="card_image"
      />
    </v-card>
    <AddPiggy @closeModal="closeModal" :dialog="dialog" />
  </div>
</template>

<script>
import AddPiggy from "../../savers/modal/piggy/AddPiggy";
export default {
  data() {
    return {
      dialog: false,
      color: "#F7E8FF",
    };
  },
  components: {
    AddPiggy,
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },
    openModal() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.add_saver {
  height: 12vh !important;
  padding: 15px;
}
.card_image {
  position: absolute;
  right: 1rem;
  bottom: 0;
}
</style>