<template>
  <div>
    <v-dialog v-model="dialog" persistent :width="returnWidth">
      <v-card class="pb-4 airtime">
        <v-row>
          <v-col md="5" class="mt-2">
            <div class="d-flex">
              <div class="image_background">
                <img src="@/assets/airtime.svg" height="20" contain />
              </div>

              <span class="mx-2 mt-1">
                <h5>Airtime & Data</h5>
              </span>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3">
            <v-btn icon color="black" @click="closeModal" class="close_btn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mb-1"></v-divider>
        <div class="airtime_form mx-auto">
          <h5>SELECT NETWORK</h5>
          <v-row>
            <v-col
              md="3"
              v-for="(provider, index) in airtimeProviders"
              :key="index"
            >
              <div
                @click.prevent="() => selectProvider(provider.name)"
                :class="[
                  selectedProvider === provider.name
                    ? 'airtimeCardSelected'
                    : 'airtimeCard',
                ]"
              >
                <img :src="provider.imgSrc" height="40" contain />
              </div>
            </v-col>
          </v-row>
          <v-carousel
            v-model="model"
            hide-delimiter-background
            hide-delimiters
            height="25"
            class="airtime_carousel"
          >
            <v-carousel-item v-for="(item, index) in items" :key="index">
              <h5>{{ item }}</h5>
            </v-carousel-item>
          </v-carousel>
          <div class="airtime_data_form">
            <ValidationObserver>
              <v-form ref="form" @submit.prevent="buyAirtime">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="data plan"
                  v-if="isData"
                >
                  <h5>Data Plans</h5>
                  <v-select
                    v-model="dataPlan"
                    solo
                    outlined
                    label="Select a plan"
                    :loading="dataPlansloading"
                    :disabled="dataPlansloading"
                    :items="getDataPlans[selectedProvider]"
                    item-text="name"
                    color="primary"
                    return-object
                  >
                  </v-select>
                  <span id="error" v-if="false">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="amount"
                >
                  <h5>Amount</h5>
                  <v-text-field
                    v-model="amount"
                    outlined
                    solo
                    label="Enter Amount"
                    color="primary"
                  ></v-text-field>
                  <span id="error" v-if="false">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="phonenumber"
                >
                  <h5>Phone Number</h5>
                  <v-text-field
                    v-model="phoneNumber"
                    outlined
                    solo
                    label="Enter Phone Number"
                    color="primary"
                  ></v-text-field>
                  <span id="error" v-if="false">{{ errors[0] }}</span>
                </ValidationProvider>

                <v-btn
                  color="primary"
                  type="submit"
                  class="mt-8"
                  large
                  width="100%"
                  :disabled="buttonDisable"
                >
                  BUY
                </v-btn>
              </v-form>
            </ValidationObserver>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <TransactionPin
      :dialog="pinDialog"
      :transaction="transaction"
      :transact="transact"
      :details="details"
      @closeModal="closePinModal"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import { buyAirtimeData } from "@/services/api-services";
import TransactionPin from "./TransactionPin";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TransactionPin,
  },
  data() {
    return {
      pinDialog: false,
      transaction: {},
      details: {},
      airtimeCard: "airtimeCard",
      airtimeCardSelected: "airtimeCardSelected",
      isData: true,
      loading: false,
      dataPlansloading: false,
      model: 0,
      items: ["Data", "Airtime"],
      dataPlan: "",
      amount: "",
      phoneNumber: "",
      selectedProvider: "",
      airtimeProviders: [
        {
          name: "mtn",
          imgSrc: require("../../../../assets/mtn.svg"),
        },
        {
          name: "glo",
          imgSrc: require("../../../../assets/glo.svg"),
        },
        {
          name: "airtel",
          imgSrc: require("../../../../assets/airtel.svg"),
        },
        {
          name: "etisalat",
          imgSrc: require("../../../../assets/etisalat.svg"),
        },
      ],
    };
  },
  watch: {
    model(newValue, oldvalue) {
      if (newValue === 0) {
        this.isData = true;
      } else {
        this.isData = false;
      }
    },
    selectedProvider(newValue, oldvalue) {
      if (newValue !== oldvalue && newValue !== "") {
        if (!(this.getDataPlans[newValue].length > 0))
          this.loadDataPlans(newValue);
      }
    },
  },
  props: {
    dialog: Boolean,
  },
  methods: {
    ...mapActions("payments", ["dataPlans"]),
    closeModal() {
      this.selectedProvider = "";
      this.dataPlan = "";
      this.$emit("closeModal");
    },
    buyAirtime() {
      this.details = {
        network: this.selectedProvider,
        to: this.phoneNumber,
        amount: this.amount,
        package: this.dataPlan.variation_code,
      };
      if (this.model === 0) {
        this.transaction = {
          service_id: `${this.selectedProvider + "-data"}`,
          amount: this.amount,
          phone_no: this.phoneNumber,
          billersCode: "08139156449",
          variation_code: this.dataPlan.variation_code,
        };
      } else {
        this.transaction = {
          service_id: `${this.selectedProvider + "-data"}`,
          amount: this.amount,
          phone_no: this.phoneNumber,
        };
      }

      console.log(this.details);
      this.pinDialog = true;
      this.closeModal();
    },
    closePinModal() {
      this.pinDialog = false;
    },
    transact(data) {
      return buyAirtimeData(data, this.selectedProvider).then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    selectProvider(provider) {
      if (this.selectedProvider === provider) {
        this.selectedProvider = "";
      } else {
        this.selectedProvider = provider;
      }
    },
    loadDataPlans(provider) {
      this.dataPlansloading = true;
      this.dataPlans(provider).then(
        (res) => {
          this.dataPlansloading = false;
        },
        (err) => {
          this.dataPlansloading = false;
        }
      );
    },
  },
  computed: {
    ...mapGetters("payments", ["getDataPlans"]),
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
    buttonDisable() {
      if (
        this.amount !== "" &&
        this.phoneNumber !== "" &&
        this.selectedProvider !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.airtime {
  contain: content;
}
.airtimeCard,
.airtimeCardSelected img {
  margin-top: 10px;
}
.airtimeCard {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 0;
  box-shadow: 0px 11px 20px rgba(130, 130, 130, 0.15) !important ;
}
.airtimeCardSelected {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 0;
  box-shadow: 0px 11px 20px rgba(130, 130, 130, 0.15) !important ;
  border: 0.5px solid #272253;
}
.airtime_data_form {
  margin-top: 12px;
  padding: 10px 5px;
}
.airtime_data_form h5 {
  font-size: 10px;
  text-align: left;
  font-weight: 600;
}
.airtime_form h5 {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}
.airtime_carousel >>> button {
  background-color: white;
}
.airtime_carousel >>> button :hover {
  background-color: #d0ddff;
}
.airtime_carousel >>> i {
  color: #272253 !important;
}
.airtime_carousel >>> .v-responsive__content {
  margin-top: 4px;
}
.airtime_carousel {
  position: relative;
}
.airtime_data_form span {
  font-size: 10px;
}
.airtime_data_form
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.airtime_data_form
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.airtime_data_form >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.airtime_data_form >>> label {
  font-size: 12px;
}
.airtime_data_form >>> .v-select__selection {
  font-size: 13px;
  color: #272253 !important;
  text-transform: uppercase;
  font-weight: 600;
}
.close_btn {
  position: absolute;
  right: 10px;
}
.image_background {
  background-color: #f4e8fa;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
  text-align: center;
  width: 35px;
}
.airtime_form {
  padding: 0 30px;
  text-align: center;
}
</style>