var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-6",attrs:{"flat":""}},[_c('h4',[_vm._v("Recent Transactions")]),_c('div',{staticClass:"transaction_header mt-6"},[_c('h5')]),_c('div',{staticClass:"transaction_body my-4"},_vm._l((_vm.agentTransactions),function(transaction,index){return _c('div',{key:index,staticClass:"d-flex my-4"},[_c('v-avatar',{attrs:{"color":transaction.verified
            ? _vm.transactionType(transaction.transaction_type)
              ? '#CCCCFF'
              : '#D2FFE7'
            : '#ffdbe7',"size":"34"}},[_c('v-icon',{attrs:{"color":transaction.verified
              ? _vm.transactionType(transaction.transaction_type)
                ? '#1F51FF'
                : '#13C7A6'
              : 'red',"small":""}},[_vm._v(" mdi-wallet ")])],1),_c('div',{staticClass:"transaction_details"},[_c('h5',[_vm._v(_vm._s(transaction.title))]),_c('p',[_vm._v(_vm._s(_vm.transformDate(transaction.created)))])]),_c('div',{staticClass:"transaction_amount"},[_c('h5',[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"primary","small":""}},[_vm._v(" mdi-currency-ngn ")]),_vm._v(_vm._s(transaction.transaction_amount)+" ")],1)])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }