<template>
  <v-dialog v-model="piggyDialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        depressed
        color="white"
        outlined
        @click.prevent="openPiggyDialog"
        >Open</v-btn
      >
    </template>
    <v-card class="container">
      <div class="d-flex header mb-6">
        <div>
          <h4 v-if="type === 'withdraw'">
            Withdraw From {{ piggyDetails.title }}
          </h4>
          <h4 v-if="type === 'topup'">Top-Up {{ piggyDetails.title }}</h4>
          <p>
            Available Balance:
            <v-icon color="grey" x-small> mdi-currency-ngn </v-icon
            >{{ piggyDetails.piggy_balance }}
          </p>
        </div>
        <v-btn icon color="black" @click="closepiggyDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <p class="field_title" v-if="type === 'withdraw'">Amount To Withdraw</p>
      <p class="field_title" v-if="type === 'topup'">Amount To Top-up</p>
      <v-text-field
        v-model="amount"
        outlined
        type="number"
        color="primary"
      ></v-text-field>
      <p class="field_title">Saver's Pin</p>
      <v-text-field
        v-model="pin"
        outlined
        type="number"
        color="primary"
      ></v-text-field>

      <v-btn
        large
        width="100%"
        class="my-4"
        color="primary"
        v-if="type === 'withdraw'"
        @click.prevent="withdraw"
        :loading="buttonLoading"
        >WITHDRAW</v-btn
      >
      <v-btn
        large
        width="100%"
        class="my-4"
        color="primary"
        v-if="type === 'topup'"
        @click.prevent="topUp"
        :loading="buttonLoading"
        >TOP-UP PIGGY</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { topUpPiggy, withdrawPiggy } from "../../../../services/api-services";
export default {
  data() {
    return {
      piggyDialog: false,
      amount: "",
      pin: "",
      buttonLoading: false,
    };
  },
  props: {
    piggyDetails: Object,
    transactionType: String,
  },

  methods: {
    closepiggyDialog() {
      this.piggyDialog = false;
      this.clearFields();
    },
    openPiggyDialog() {
      this.piggyDialog = true;
    },
    clearFields() {
      this.amount = "";
      this.pin = "";
    },
    async topUp() {
      let data = {
        user_phone: this.$route.query.phone,
        user_pin: this.pin,
        transaction_amount: this.amount,
      };
      this.buttonLoading = true;
      await topUpPiggy(data)
        .then((res) => {
          console.log(res);

          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Top-Up Successful",
            success: true,
          });
          this.closepiggyDialog();
        })
        .catch((err) => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Top-Up Unsuccessful",
            success: false,
          });
          this.closepiggyDialog();
          console.error(err);
        })
        .finally(() => (this.buttonLoading = false));
    },
    async withdraw() {
      let data = {
        user_phone: this.$route.query.phone,
        user_pin: this.pin,
        transaction_amount: this.amount,
      };

      this.buttonLoading = true;
      await withdrawPiggy(data)
        .then((res) => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Withdraw Successful",
            success: true,
          });
          this.closepiggyDialog();
          console.log(res);
        })
        .catch((err) => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Withdraw Unsuccessful",
            success: false,
          });
          this.closepiggyDialog();
          console.error(err);
        })
        .finally(() => (this.buttonLoading = false));
    },
  },
  computed: {
    type() {
      return this.transactionType;
    },
  },
};
</script>

<style scoped>
.header {
  justify-content: space-between;
}
.field_title {
  color: #272253;
}
.container {
  padding: 25px 40px !important;
}
.container p {
  font-size: 12px;
  margin-bottom: unset !important;
}

.container
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 13px;
  font-weight: 600;
}
.container >>> fieldset {
  border: unset !important;
}
.container >>> .v-text-field__slot input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.v-text-field__slot input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>