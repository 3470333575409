<template>
  <div class="dashboard">
    <v-card class="top_card mx-auto" :width="returnWidth">
      <v-row>
        <v-col md="6" sm="12" cols="12" class="top_column">
          <h4>
            Welcome
            {{
              agent.first_name.charAt(0).toUpperCase() +
              agent.first_name.slice(1) +
              " " +
              agent.last_name.charAt(0).toUpperCase() +
              agent.last_name.slice(1)
            }}
          </h4>
          <p class="my-2">What would you like to do today?</p>
          <WalletBalanceCared />
        </v-col>
        <v-col md="6" sm="12" cols="12" class="top_column">
          <p class="mt-10">QUICK ACTIONS</p>
          <AddSaver :class="[$vuetify.breakpoint.smAndDown ? '' : 'mb-2']" />
          <v-row>
            <v-col md="6" sm="12" cols="12">
              <PayBills
                :class="[$vuetify.breakpoint.smAndDown ? mobile : web]"
              />
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <CreatePiggy />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mx-auto mt-4" flat :width="returnWidth">
      <v-row>
        <v-col md="6" sm="12" class="mt-2">
          <Transactions />
        </v-col>
        <v-col md="6" sm="12" class="mt-2">
          <Stats />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import WalletBalanceCared from "./balanceCard/WalletBalanceCard.vue";
import AddSaver from "./quickActions/AddSaver";
import CreatePiggy from "./quickActions/CreatePiggy.vue";
import PayBills from "./quickActions/PayBills";
import Transactions from "./transactions/Transactions.vue";
import Stats from "./stats/Stats.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      mobile: "mobile_view",
      web: "web_view",
    };
  },
  components: {
    WalletBalanceCared,
    AddSaver,
    PayBills,
    CreatePiggy,
    Transactions,
    Stats,
  },
  computed: {
    ...mapGetters("agent", ["agent"]),
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "80vw";
      }
    },
  },
};
</script>

<style scoped>
/* .dashboard >>> ::-webkit-scrollbar {
  background: transparent !important;

  width: 4px;
  height: 40px;
}
.dashboard >>> ::-webkit-scrollbar-thumb {
  background: #f4f4f1;

  max-height: 40px !important;
  height: 40px !important;
  border-radius: 12px;
} */
.mobile_view {
  margin-top: 10px;
  margin-bottom: 10px;
}
.web_view {
  margin-right: 10px;
}

.top_card {
  padding: 10px 10px !important;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.top_card p {
  font-size: 12px;
}
.dashboard >>> .col,
.col-12 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.top_column {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>