<template>
  <v-card :width="returnWidth" class="px-8 pt-3 piggy">
    <v-row justify="end">
      <v-btn icon color="black" @click="closeModal" class="close_btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <div class="py-2 piggy_form">
      <div class="ml-3 header">
        <h5>Confirm Details</h5>
      </div>
      <div class="d-flex mt-4 ml-2">
        <v-avatar size="56" color="#EEECFF">
          <h4>
            {{
              getSaverProfile.first_name.split("")[0] +
              getSaverProfile.last_name.split("")[0]
            }}
          </h4>
        </v-avatar>

        <div class="ml-2 mt-1">
          <h4>{{ getSaverProfile.first_name + getSaverProfile.last_name }}</h4>
          <p>{{ getSaverProfile.phone }}</p>
        </div>
      </div>

      <v-row class="sub_details mt-8 ml-3">
        <v-col md="6" sm="12">
          <p>AMOUNT TO SAVE</p>
          <h5 class="mt-2">{{ getSaverProfile.target_amount }}</h5>
        </v-col>
        <v-col md="6" sm="12">
          <p>SAVING FREQUENCY</p>
          <h5 class="mt-2">{{ getSaverProfile.frequency }}</h5>
        </v-col>
      </v-row>
      <div class="divider">
        <p>NEXT OF KIN</p>
        <v-divider></v-divider>
      </div>

      <v-row>
        <v-col md="6" sm="12">
          <p>NAME</p>
          <h5>{{ getSaverProfile.nok_fullname }}</h5>
        </v-col>
        <v-col md="6" sm="12">
          <p>RELATIONSHIP</p>
          <h5>Family</h5>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col md="6" sm="12">
          <p>PHONE</p>
          <h5>{{ getSaverProfile.nok_phone }}</h5>
        </v-col>
        <v-col md="6" sm="12">
          <p>REASON</p>
          <h5>{{ getSaverProfile.description }}</h5>
        </v-col>
      </v-row>

      <div class="my-4">
        <v-row>
          <v-col md="3">
            <v-btn
              color="primary"
              large
              depressed
              outlined
              @click.prevent="() => back()"
            >
              BACK
            </v-btn>
          </v-col>
          <v-col md="3">
            <v-btn
              color="primary"
              large
              :loading="loading"
              class="ml-3"
              @click.prevent="() => confirmDetails()"
            >
              FINISH
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3">
            <h4>3/3</h4>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { addSaver } from "../../../../services/api-services";
export default {
  data() {
    return {
      loading: false,
      disabled: true,
      piggyName: "",
      amount: "",
      savingFrequency: "",
      piggyReason: "",
      date: "",
      frequencies: ["Yearly", "Monthly", "Weekly"],
    };
  },
  props: {
    dialog: Boolean,
  },
  computed: {
    ...mapGetters("customers", ["getSaverProfile"]),
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
  },
  methods: {
    ...mapMutations("customers", ["addSaver"]),
    closeModal() {
      this.$emit("closeModal");
    },
    cancel() {
      this.closeModal();
      this.fullname = "";

      this.phonenumber = "";
      this.relationship = "";
    },
    back() {
      this.$emit("updateStep", 2);
    },
    confirmDetails() {
      this.loading = true;
      addSaver(this.getSaverProfile).then(
        (res) => {
          this.loading = false;
          this.addSaver(this.getSaverProfile);
          this.closeModal();
          console.log(res);
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    },
  },
};
</script>

<style scoped>
.divider {
  margin: 14px 14px;
}
.piggy
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.piggy >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.piggy_form p {
  color: #272253;
  font-size: 13px;
  font-weight: 500;
}
.piggy_form >>> label,
input {
  font-size: 12px !important;
}

.header p {
  font-size: 10px;
  line-height: 18px;
}
.sub_details {
  background-color: #eeecff;
}
</style>