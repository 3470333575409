<template>
  <div>
    <v-card
      x
      :color="color"
      :elevation="2"
      class="add_saver"
      @click="() => openModal()"
    >
      <h5>Pay Bills</h5>
      <img
        src="../../../../assets/card.svg"
        alt=""
        height="30"
        class="card_image"
      />
    </v-card>
    <v-dialog v-model="dialog" persistent :width="returnWidth">
      <v-card>
        <div class="d-flex justify-space-between pa-4">
          <h5>Choose Utility</h5>
          <v-btn icon color="black" @click="closeModal" class="close_btn">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="pa-4 d-flex justify-space-between">
          <v-btn
            class="text-center my-2"
            color="primary"
            @click.prevent="openElectricityDialog()"
          >
            Electricity Bill
          </v-btn>
          <v-btn
            class="text-center my-2"
            color="primary"
            outlined
            @click.prevent="openCableDialog()"
          >
            Cable Bill
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <Electricity
      :dialog="electricityDialog"
      @closeModal="closeElectricityDialog"
    />
    <Cable :dialog="cableDialog" @closeModal="closeCableDialog" />
  </div>
</template>

<script>
import Electricity from "../../payments/modals/Electricity";
import Cable from "../../payments/modals/Cable";
export default {
  data() {
    return {
      dialog: false,
      electricityDialog: false,
      cableDialog: false,
      color: "#FFEDBF",
    };
  },
  components: {
    Electricity,
    Cable,
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    openElectricityDialog() {
      this.dialog = false;
      this.electricityDialog = true;
    },
    openCableDialog() {
      this.dialog = false;
      this.cableDialog = true;
    },
    closeElectricityDialog() {
      this.electricityDialog = false;
    },
    closeCableDialog() {
      this.cableDialog = false;
    },
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30%";
      }
    },
  },
};
</script>

<style scoped>
.add_saver {
  height: 12vh !important;
  padding: 15px;
}
.card_image {
  position: absolute;
  right: 1rem;
  bottom: 0;
}
</style>