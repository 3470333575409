var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"bottom_card mr-6 px-6 pb-4",attrs:{"width":_vm.returnWidth,"loading":_vm.loading}},[_c('h4',{staticClass:"mt-4"},[_vm._v("Recent Transactions")]),_c('div',{staticClass:"transaction_header mt-6"},[_c('h5',[_vm._v("TODAY")])]),(!(_vm.todayTransactions.length > 0))?_c('div',{staticClass:"mt-6"},[_c('v-img',{attrs:{"src":require("../../../../assets/emptyUser.svg"),"width":"80","height":"80"}}),_c('h5',[_vm._v("No Transaction")]),_c('h5',[_vm._v("Made yet")])],1):_c('div',{staticClass:"transaction_body my-4"},_vm._l((_vm.todayTransactions),function(transaction,index){return _c('div',{key:index,staticClass:"d-flex my-4"},[_c('v-avatar',{attrs:{"color":transaction.verified
            ? _vm.transactionType(transaction.transaction_type)
              ? '#CCCCFF'
              : '#D2FFE7'
            : '#ffdbe7',"size":"34"}},[_c('v-icon',{attrs:{"color":transaction.verified
              ? _vm.transactionType(transaction.transaction_type)
                ? '#1F51FF'
                : '#13C7A6'
              : 'red',"small":""}},[_vm._v(" mdi-wallet ")])],1),_c('div',{staticClass:"transaction_details"},[_c('h5',[_vm._v(_vm._s(transaction.transaction_type))]),_c('p',[_vm._v(_vm._s(_vm.transformDate(transaction.created)))])]),_c('div',{staticClass:"transaction_amount"},[_c('h5',[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"primary","small":""}},[_vm._v(" mdi-currency-ngn ")]),_vm._v(_vm._s(transaction.transaction_amount)+" ")],1)])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }