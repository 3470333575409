<template>
  <v-card :width="returnWidth" class="stats" :loading="loading">
    <h4 class="mt-4">Stats</h4>

    <div class="all_saver">
      <p>ALL SAVER</p>
      <h4>{{ getSavers.length }}</h4>
    </div>

    <div class="active_saver">
      <p>ACTIVE PIGGY</p>
      <h4>1</h4>
    </div>

    <div class="bottom_container">
      <p>YOUR EARNINGS(TOTAL)</p>
      <h5>
        <v-icon color="primary" small> mdi-currency-ngn </v-icon
        >{{ getBalance }}
      </h5>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("customers", ["getSavers"]),
    ...mapGetters("agent", ["getBalance"]),
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "40vw";
      }
    },
  },
  methods: {
    ...mapActions("customers", ["getAllCustomers"]),
  },
  created() {
    if (!(this.getSavers.length > 0)) {
      this.loading = true;
      this.getAllCustomers().then(
        (res) => (this.loading = false),
        (err) => (this.loading = false)
      );
    }
  },
};
</script>

<style scoped>
.stats {
  height: 48vh;
  contain: content;
  padding: 0 20px !important;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.stats p {
  font-size: 12px;
}
.all_saver,
.active_saver {
  background-color: #f7f9fc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.bottom_container {
  background-color: #f7f9fc;
  border-radius: 10px;
  padding: 10px;

  margin-bottom: 10px;
}
.stats >>> .col,
.col-12 {
  padding-left: 0 !important;
  padding-right: 10 !important;
}
</style>