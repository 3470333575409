<template>
  <v-dialog v-model="withdrawDialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        depressed
        color="white"
        outlined
        @click.prevent="openWithdrawDialog"
        >WITHDRAW</v-btn
      >
    </template>
    <v-card :loading="accountNumberVerified">
      <v-row justify="end">
        <v-btn icon color="black" @click="closeWithdrawDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <div class="container">
        <div class="mb-4">
          <h4>Withdraw From Account</h4>
          <p>
            Available balance:
            <v-icon color="grey" x-small> mdi-currency-ngn </v-icon
            >{{ balance }}
          </p>
        </div>

        <h5 class="field_title">Select Your Bank</h5>
        <v-select
          :items="banks"
          outlined
          :loading="bankLoading"
          :disabled="bankDropdownDisabled"
          item-text="name"
          item-value="code"
          v-model="bankCode"
        ></v-select>
        <h5 v-if="bankCode !== ''" class="field_title">Enter Account Number</h5>
        <v-text-field
          v-if="bankCode !== ''"
          v-model="accountNumber"
          outlined
          type="number"
          color="primary"
          :disabled="accountDisabled"
        ></v-text-field>
        <div class="d-flex justify-space-between mb-2" v-if="verified">
          <v-chip color="teal" small
            ><span class="verified_icon">VERIFIED</span></v-chip
          >
          <h5>{{ accountName }}</h5>
        </div>
        <h5 v-if="bankCode !== ''" class="field_title">
          Reasonr For Withdrawal
        </h5>
        <v-text-field
          v-if="bankCode !== ''"
          v-model="reason"
          outlined
          color="primary"
        ></v-text-field>
        <h5 class="field_title">Enter Amount To Withdraw</h5>
        <v-text-field
          v-model="amount"
          outlined
          color="primary"
          class="pin"
        ></v-text-field>

        <v-btn
          :disabled="disableWithdrawButton"
          large
          width="100%"
          class="my-4"
          color="primary"
          @click.prevent="fundwallet()"
          >WITHDRAW</v-btn
        >

        <TransactionPin
          :transaction="transaction"
          :transact="transact"
          :dialog="pinDialog"
          @closeModal="closePinModal"
          :details="details"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import TransactionPin from "@/agent/components/payments/modals/TransactionPin";
import {
  getBankList,
  createTransferRecipient,
  initiateWithdraw,
} from "../../../services/api-services";
export default {
  data() {
    return {
      details: {},
      amount: "",
      reason: "",
      pin: "",
      accountNumber: "",
      pinDialog: false,
      transaction: {},
      withdrawDialog: false,
      bankLoading: false,
      bankDropdownDisabled: false,
      accountDisabled: false,
      banks: [],
      bankCode: "",
      verified: false,
      recipientNumber: "",
      accountNumberVerified: false,
      accountName: "",
    };
  },
  components: {
    TransactionPin,
  },
  props: {
    balance: Number,
  },
  watch: {
    accountNumber: function (newValue, oldValue) {
      if (newValue.length === 10) {
        this.accountNumberVerified = true;
        this.accountDisabled = true;
        let data = {
          account_number: this.accountNumber,
          bank_code: this.bankCode,
        };
        createTransferRecipient(data)
          .then((res) => {
            console.log(res.data);
            let { recipient_code } = res.data.data;
            let {
              details: { account_name },
            } = res.data.data;

            this.recipientNumber = recipient_code;
            this.accountName = account_name;
            this.verified = res.data.status;
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.accountDisabled = false;
            this.accountNumberVerified = false;
          });
      } else {
        this.accountDisabled = false;
        this.accountNumberVerified = false;
      }
    },
    withdrawDialog: function (newValue, oldValue) {
      if (newValue) {
        this.bankLoading = true;
        this.bankDropdownDisabled = true;
        getBankList()
          .then((res) => {
            this.banks = res.data.data;
            console.log(res.data.data);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.bankLoading = false;
            this.bankDropdownDisabled = false;
          });
      }
    },
  },
  computed: {
    disableWithdrawButton() {
      return this.amount === "" || parseInt(this.amount) > this.balance;
    },
  },
  methods: {
    fundwallet() {
      this.transaction.amount = this.amount;
      this.transaction.recipient = this.recipientNumber;
      this.transaction.reason = this.reason;

      this.details = {
        ...this.details,
        accountNumber: this.accountNumber,
        amount: this.amount,
        reason: this.reason,
      };
      this.closeWithdrawDialog();
      this.pinDialog = true;
    },
    closePinModal() {
      this.pinDialog = false;
    },
    async transact(data) {
      return await initiateWithdraw(data)
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err));
    },
    openWithdrawDialog() {
      this.withdrawDialog = true;
    },
    closeWithdrawDialog() {
      this.withdrawDialog = false;
    },
  },
};
</script>

<style>
.container {
  padding: 10px 40px !important;
}
.container p {
  font-size: 12px;
}

.container
  > .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 13px;
  font-weight: 600;
}
fieldset {
  border: unset !important;
}
.field_title {
  text-align: left !important;
  font-weight: 500;
}
.pin >>> fieldset {
  border: 1px solid #272253 !important;
}
.title_text {
  font-size: 18px;
  color: #272253;
  letter-spacing: 1px;
}

.verified_icon {
  color: white !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>