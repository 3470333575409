<template>
  <v-card :width="returnWidth" class="px-8 pt-3 kin">
    <v-row justify="end">
      <v-btn icon color="black" @click="closeModal" class="close_btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <div class="py-2 kin_form">
      <div class="ml-3 header">
        <h5>Complete Profile</h5>
        <p class="mt-2">
          Almost done! Provide details of the saver’s Next of Kin
        </p>

        <h5 class="mt-4">NEXT OF KIN</h5>
      </div>
      <v-row class="mt-6">
        <v-col md="6" sm="12" cols="12">
          <p>Full Name</p>
          <v-text-field
            v-model="fullname"
            outlined
            solo
            label="Enter First Name"
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <p>Phone Number</p>
          <v-text-field
            v-model="phonenumber"
            outlined
            type="number"
            class="number_input"
            solo
            label="Enter Phone Number"
            color="primary"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" sm="12">
          <p>Relationship</p>
          <v-select
            v-model="relationship"
            outlined
            :items="relationships"
            solo
            label="Relationship"
            color="primary"
          ></v-select>
        </v-col>
      </v-row>

      <div class="my-4">
        <v-row>
          <v-col md="3">
            <v-btn
              color="primary"
              class="mx-2"
              large
              outlined
              @click.prevent="() => back()"
            >
              BACK
            </v-btn>
          </v-col>
          <v-col md="3">
            <v-btn
              color="primary"
              class="mx-2"
              large
              @click.prevent="() => addkin()"
            >
              NEXT
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3">
            <h4>1/3</h4>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      loading: false,
      disabled: true,
      fullname: "",
      phonenumber: "",
      relationship: "",
      relationships: ["Dad", "Mum", "Sister", "Brother", "Extended"],
    };
  },
  props: {
    dialog: Boolean,
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
  },
  methods: {
    ...mapMutations("customers", ["saveUserProfile"]),
    closeModal() {
      this.$emit("closeModal");
    },
    cancel() {
      this.closeModal();
      this.fullname = "";

      this.phonenumber = "";
      this.relationship = "";
    },
    addkin() {
      let data = {
        nok_fullname: this.fullname,
        nok_phone: this.phonenumber,
      };
      this.saveUserProfile(data);
      this.$emit("updateStep", 2);
    },
    back() {
      this.$emit("closeProfileModal");
      this.$emit("openAddSaverModal");
    },
  },
};
</script>

<style scoped>
.number_input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.number_input >>> input::-webkit-outer-spin-button,
.number_input >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.kin
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.kin >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.kin_form p {
  color: #272253;
  font-size: 13px;
  font-weight: 500;
}
.kin_form >>> label,
input {
  font-size: 12px !important;
}

.header p {
  font-size: 13px;
  line-height: 18px;
}
</style>