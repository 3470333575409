<template>
  <div>
    <slot name="button"> </slot>
    <v-dialog v-model="dialog" persistent :width="returnWidth">
      <v-card :width="returnWidth">
        <v-row justify="end">
          <v-btn icon color="black" @click="closeModal()" class="mt-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <div class="px-6 pb-6">
          <h3>Suspend Agent(s)</h3>
          <p>
            You’re about to suspend {{ agents.length }} agent(s). Once an agent
            is suspended, the agent would not be able to login to Ajopoint.
          </p>

          <div class="d-flex mt-4">
            <v-btn color="primary" large outlined @click="cancel()"
              >CANCEL</v-btn
            >
            <v-btn
              color="primary"
              large
              class="ml-4"
              @click="toggleAgentStatus()"
              :loading="loading"
              >{{ agentStatus }}</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { suspend, activate } from "../../services/api-services";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  props: {
    open: Boolean,
    agents: Array,
  },
  watch: {
    open: function (newValue, oldValue) {
      if (newValue) {
        this.dialog = true;
      } else if (!newValue) {
        this.dialog = true;
      }
    },
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
    agentStatus() {
      if (this.agents.length > 0) {
        if (this.agents[0].is_active) {
          return "SUSPEND AGENT";
        } else {
          return "ACTIVATE AGENT";
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },
    cancel() {
      this.closeModal();
    },
    toggleAgentStatus() {
      let user = this.agents[0].phone;
      this.loading = true;
      if (this.agents[0].is_active) {
        suspend(user).then(
          (res) => {
            this.loading = false;
            this.$store.commit("snackbar/SHOW_SNACKBAR", {
              message: "Agent Suspended",
              success: true,
            });
            this.closeModal();
            window.location.reload();
            console.log(res);
          },
          (err) => {
            this.loading = false;
            this.$store.commit("snackbar/SHOW_SNACKBAR", {
              message: "Unable To Suspend Agent",
              success: false,
            });
            this.closeModal();
            console.error(err);
          }
        );
      } else {
        activate(user).then(
          (res) => {
            this.loading = false;
            this.$store.commit("snackbar/SHOW_SNACKBAR", {
              message: "Agent Suspended",
              success: true,
            });
            this.closeModal();
            window.location.reload();
            console.log(res);
          },
          (err) => {
            this.loading = false;
            this.$store.commit("snackbar/SHOW_SNACKBAR", {
              message: "Unable To Suspend Agent",
              success: false,
            });
            this.closeModal();
            console.error(err);
          }
        );
      }
    },
  },
};
</script>

<style scoped>
p {
  font-size: 12px;
}
</style>