<template>
  <v-row>
    <v-col md="9" sm="12">
      <v-card class="card_left" rounded="lg">
        <div class="mt-4 d-flex">
          <div md="7" sm="6">
            <h4 class="top_card_title">Savers</h4>
          </div>
          <v-spacer></v-spacer>
          <div md="2" sm="6">
            <AddSaver :open="open">
              <template v-slot:button>
                <v-btn
                  color="primary btn_header mr-2"
                  @click.prevent="openModal()"
                  >+ ADD SAVER</v-btn
                >
              </template>
            </AddSaver>
          </div>
        </div>

        <div class="d-flex insight_cards mt-2">
          <div class="all_saver">
            <p>ALL SAVER</p>
            <h3>{{ getSavers.length }}</h3>
          </div>
          <div class="active_saver">
            <p>ACTIVE PIGGY</p>
            <h3>1</h3>
          </div>
          <div class="active_saver">
            <p>ACTIVE PIGGY</p>
            <h3>1</h3>
          </div>
        </div>
        <v-text-field
          class="mt-4"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          solo
          label="Search"
        ></v-text-field>
        <v-divider class="my-4"></v-divider>
        <div class="savers px-6">
          <v-row>
            <v-col md="1"><p>#</p></v-col>
            <v-col md="6"><p>SAVER</p></v-col>
            <v-col md="2"><p class="text-right">ACTIVE PIGGY</p></v-col>
            <v-col md="2"><p class="text-right">BALANCE</p></v-col>
          </v-row>
          <v-skeleton-loader type="list-item-avatar@2" :loading="loading">
            <div v-if="savers.length > 0">
              <!-- <div class="savers_details">
                <div v-if="returnSearch.length > 0 || search !== ''">
                  <v-row
                    v-for="(saver, index) in paginatedFilteredSavers"
                    :key="index"
                  >
                    <v-col md="1"
                      ><h5>{{ index + 1 }}</h5></v-col
                    >
                    <v-col md="6"
                      ><div class="d-flex mt-n3">
                        <v-avatar color="#EEECFF" size="40"
                          ><h4>
                            {{ returnIntials(saver.detail.name) }}
                          </h4></v-avatar
                        >
                        <div class="ml-2 mt-1">
                          <h5>{{ saver.detail.name }}</h5>
                          <p>{{ saver.detail.phone }}</p>
                        </div>
                      </div></v-col
                    >
                    <v-col md="2"
                      ><h5 class="text-right">
                        {{ saver.active_piggy }}
                      </h5></v-col
                    >
                    <v-col md="2">
                      <h5 class="text-right">
                        <v-icon color="primary" small> mdi-currency-ngn </v-icon
                        >{{ saver.detail.balance }}
                      </h5></v-col
                    >
                  </v-row>
                </div>
                <div v-else>
                  <v-row
                    v-for="(saver, index) in paginatedSavers"
                    :key="index"
                    class="saver_row"
                    @click.prevent="saverDetails(saver)"
                  >
                    <v-col md="1"
                      ><h5>{{ index + 1 }}</h5></v-col
                    >
                    <v-col md="6"
                      ><div class="d-flex mt-n3">
                        <v-avatar color="#EEECFF" size="40"
                          ><h4>
                            {{ returnIntials(saver.detail.name) }}
                          </h4></v-avatar
                        >
                        <div class="ml-2 mt-1">
                          <h5>{{ saver.detail.name }}</h5>
                          <p>{{ saver.detail.phone }}</p>
                        </div>
                      </div></v-col
                    >
                    <v-col md="2"
                      ><h5 class="text-right">
                        {{ saver.active_piggy }}
                      </h5></v-col
                    >
                    <v-col md="2">
                      <h5 class="text-right">
                        <v-icon color="primary" small> mdi-currency-ngn </v-icon
                        >{{ saver.detail.balance }}
                      </h5></v-col
                    >
                  </v-row>
                </div>
              </div> -->
              <v-data-table
                :custom-filter="
                  (value, search, item) => searchFilter(value, search, item)
                "
                :headers="saverHeaders"
                :items="paginatedSavers"
                :search="search"
                hide-default-footer
                hide-default-header
                :items-per-page="10"
                :page.sync="page"
                @page-count="pageCount = $event"
                class="saversTable"
              >
                <template v-slot:[`item`]="{ item, index }">
                  <v-row class="saver_row" @click.prevent="saverDetails(item)">
                    <v-col md="1"
                      ><h5>{{ index + 1 }}</h5></v-col
                    >
                    <v-col md="6"
                      ><div class="d-flex mt-n3">
                        <v-avatar color="#EEECFF" size="40"
                          ><h4>
                            {{ returnIntials(item.detail.name) }}
                          </h4></v-avatar
                        >
                        <div class="ml-2 mt-1">
                          <h5>{{ item.detail.name }}</h5>
                          <p>{{ item.detail.phone }}</p>
                        </div>
                      </div></v-col
                    >
                    <v-col md="2"
                      ><h5 class="text-right">
                        {{ item.active_piggy }}
                      </h5></v-col
                    >
                    <v-col md="2">
                      <h5 class="text-right">
                        <v-icon color="primary" small> mdi-currency-ngn </v-icon
                        >{{ item.detail.balance }}
                      </h5></v-col
                    >
                  </v-row>
                </template>
              </v-data-table>
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#960C0C"
                :total-visible="4"
              ></v-pagination>
            </div>
            <div v-else class="empty_users">
              <v-img
                src="../../../assets/emptyUser.svg"
                width="80"
                height="80"
              ></v-img>
              <h5>You haven't added any</h5>
              <h5>Savers yet</h5>
            </div>
          </v-skeleton-loader>
        </div>
      </v-card>
    </v-col>
    <v-col md="3" sm="12">
      <v-card class="card_right" rounded="lg">
        <CreatePiggy />
        <MakePayment />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CreatePiggy from "../dashboard/quickActions/CreatePiggy.vue";
import MakePayment from "../dashboard/quickActions/PayBills.vue";

import AddSaver from "./AddSaver";
export default {
  data() {
    return {
      loading: false,
      open: false,
      step: 1,
      addSaverModal: false,
      verifySaverModal: false,
      profileModal: false,
      page: 1,
      pageCount: 0,
      filteredSearch: [],
      saverHeaders: [
        {
          text: "transaction",
          align: "start",
          sortable: false,
          value: "details",
        },
        { text: "user", value: "detail" },
      ],
      search: "",
    };
  },
  components: {
    CreatePiggy,
    MakePayment,
    AddSaver,
  },
  methods: {
    ...mapActions("customers", ["getAllCustomers"]),
    ...mapMutations("customers", ["updateSaverDetails"]),
    openModal() {
      this.open = !this.open;
    },
    searchFilter(value, search, item) {
      let number = () =>
        item.detail.phone !== "" ? item.detail.phone.toLocaleLowerCase() : "";
      let name = () =>
        item.detail.name !== "" ? item.detail.name.toLocaleLowerCase() : "";

      if (search !== "") {
        let searchLowercase = search.toLocaleLowerCase();
        if (
          name().indexOf(searchLowercase) === -1 &&
          number().indexOf(searchLowercase)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    returnIntials(user) {
      let stringArray = user.split(" ");
      let firstInitial = stringArray[0].substring(0, 1);
      let secondInitial = stringArray[1].substring(0, 1);
      return firstInitial.toUpperCase() + secondInitial.toUpperCase();
    },
    saverDetails(saver) {
      sessionStorage.setItem("saverDetails", JSON.stringify(saver));
      this.$router.push(
        `/agent/users/${saver.user}/?phone=${saver.detail.phone}`
      );
    },
  },
  created() {
    if (!(this.getSavers.length > 0)) {
      this.loading = true;
      this.getAllCustomers().then(
        (res) => (this.loading = false),
        (err) => (this.loading = false)
      );
    }
  },

  computed: {
    ...mapGetters("customers", ["getSavers"]),
    savers() {
      return this.getSavers;
    },
    returnProfile() {
      if (this.step === 1) {
        return NextOfKin;
      } else if (this.step === 2) {
        return CreateAPiggy;
      } else if (this.step === 3) {
        return Details;
      }
    },
    returnSearch() {
      if (this.search !== "") {
        this.filteredSearch = this.savers.filter(
          (saver) =>
            saver.detail.name
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
        );
        return this.filteredSearch;
      } else {
        this.filteredSearch = [];
        return this.filteredSearch;
      }
    },

    paginatedSavers() {
      let data = [...this.savers];
      return data;
    },
    getPageCount() {
      if (this.filteredSearch.length > 0) {
        return Math.ceil(this.filteredSearch.length / 4);
      } else {
        return Math.ceil(this.savers.length / 4);
      }
    },
    paginatedFilteredSavers() {
      let data = [...this.filteredSearch];
      return data.slice(this.start, this.end);
    },
    start() {
      let a = this.page * 4;
      let b = 4;
      return a - b;
    },
    end() {
      return this.page * 4;
    },
  },
};
</script>

<style scoped>
.saversTable {
  max-height: 55vh;
  contain: content;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(59, 58, 58) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
.saver_row {
  cursor: pointer;
}
.empty_users {
  text-align: -webkit-center;
  height: 40vh;
  margin-top: 5rem;
}

.savers p {
  color: rgb(165, 165, 165);
  font-size: 12px;
  font-weight: 500;
}
.savers_details {
  position: relative;
  height: 40vh;
  overflow-y: auto;
}
.card_left
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}

.insight_cards div {
  background-color: #f7f9fc;
  margin-right: 0.5rem;
  border-radius: 8px;
  padding: 10px;
  width: 30vw;
}

.insight_cards div > p {
  font-size: 12px;
}
.card_left {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.card_right {
  padding: 20px 20px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
  height: 30vh;
}
</style>