<template>
  <div :class="[isMobile ? mobileView : webView]">
    <v-row>
      <v-col md="7" sm="12">
        <v-card class="pa-6 user" rounded="lg" flat>
          <p class="back_button" @click.prevent="() => goBack()">
            <v-icon small>mdi-chevron-left</v-icon> BACK TO AGENTS
          </p>
          <div class="d-flex mt-4 justify-space-between">
            <div class="d-flex">
              <v-avatar size="56" color="#EEECFF">
                <h4>
                  {{
                    saver.first_name.split("")[0].toUpperCase() +
                    saver.last_name.split("")[0].toUpperCase()
                  }}
                </h4>
              </v-avatar>

              <div class="ml-4 mt-1 user_details">
                <h4>
                  {{ saver.first_name + " " + saver.last_name }}
                </h4>
                <p>{{ saver.phone }}</p>
              </div>
            </div>
            <div class="admin-actions">
              <SuspendAgent
                :open="openSuspendModal"
                :agents="selected"
                class="mr-4"
              >
                <template v-slot:button>
                  <v-btn color="primary" outlined @click="suspendAgent()">
                    {{ agentStatus }}
                  </v-btn>
                </template>
              </SuspendAgent>
              <ViewKYC />
            </div>
          </div>
          <div class="d-flex justify-space-between mt-4">
            <div class="mini_cards">
              <p>SAVERS</p>
              <h4>{{ getAgent.savers }}</h4>
            </div>
            <div class="mini_cards">
              <p>BALANCE</p>
              <h4>
                <v-icon color="primary" small> mdi-currency-ngn </v-icon
                >{{ getAgent.balance }}
              </h4>
            </div>
          </div>
          <v-text-field
            class="mt-4"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            solo
            label="Search"
          ></v-text-field>
          <div class="transaction_header mt-6">
            <h5>TRANSACTION</h5>
          </div>
          <v-skeleton-loader
            :loading="transactionloading"
            type="list-item-avatar"
          >
            <div v-if="transactions.length > 0">
              <v-data-table
                :headers="transactionHeaders"
                :items="transactions"
                :search="search"
                hide-default-footer
                hide-default-header
                class="mt-4 transaction_table"
              >
                <template v-slot:[`item.title`]="{ item }">
                  <div class="d-flex agent_details">
                    <v-avatar
                      :color="
                        item.transaction_type === 'Top-up'
                          ? '#D2FFE7'
                          : '#E4F2FF'
                      "
                      size="40"
                    >
                      <h5>
                        {{ item.transaction_type === "Top-up" ? "IN" : "OUT" }}
                      </h5>
                    </v-avatar>
                    <div class="ml-2">
                      <h4>{{ item.title !== null ? item.title : "--" }}</h4>
                      <p>{{ formatDate(item.created) }}</p>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <h4>
                    <v-icon color="primary" small> mdi-currency-ngn </v-icon
                    >{{ item.transaction_amount }}
                  </h4>
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <v-img
                src="../../../assets/emptyUser.svg"
                width="80"
                height="80"
              ></v-img>
              <h5>No Transaction</h5>
            </div>
          </v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col md="5" sm="12">
        <v-card class="view_piggy pa-8" flat>
          <div class="title">
            <p>SAVERS {{ savers.length }}</p>
          </div>

          <v-skeleton-loader :loading="saversloading" type="list-item-avatar">
            <div v-if="savers.length > 0">
              <v-data-table
                :headers="saversHeaders"
                :items="savers"
                hide-default-footer
                hide-default-header
                class="mt-4 savers_table"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div class="d-flex agent_details">
                    <v-avatar color="#EEECFF" size="40">
                      <h5>
                        {{ returnIntials(item.detail.name) }}
                      </h5>
                    </v-avatar>
                    <div class="ml-2">
                      <h4>{{ item.detail.name }}</h4>
                      <p>{{ item.detail.phone }}</p>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <h4>
                    <v-icon color="primary" small> mdi-currency-ngn </v-icon
                    >{{ item.detail.balance }}
                  </h4>
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <h5>No Savers</h5>
            </div>
          </v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  getAgentTransactions,
  getAgentSavers,
} from "../../services/api-services";
import SuspendAgent from "./SuspendAgent";
import ViewKYC from "./ViewKYC.vue";

export default {
  data() {
    return {
      createDialog: false,
      piggy: {},
      search: "",
      loading: false,
      transactionloading: false,
      saversloading: false,
      openSuspendModal: false,
      piggyLoading: false,
      piggys: [],
      dialog: false,
      colors: ["blue", "teal", "indigo"],
      presentIndex: 0,
      nextIndex: 0,
      mobileView: "mobileView",
      webView: "webView",
      filteredSearch: [],
      transactionHeaders: [
        {
          text: "transaction",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "amount", value: "amount" },
      ],
      transactions: [],
      saversHeaders: [
        {
          text: "saver",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "amount", value: "amount" },
      ],
      savers: [],
    };
  },
  components: {
    SuspendAgent,
    ViewKYC,
  },
  created() {
    this.sendAgentToStore();
    this.getTransactions();
    this.getSavers();
  },
  computed: {
    ...mapGetters("admin", ["getAgent"]),

    transactionType() {
      return function (type) {
        return type === "Utility";
      };
    },
    agentStatus() {
      if (this.getAgent.is_active) {
        return "SUSPEND AGENT";
      } else {
        return "ACTIVATE AGENT";
      }
    },
    selected() {
      return [this.saver];
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    piggyColor() {
      return this.colors[this.presentIndex];
    },
    saver() {
      return {
        first_name: this.getAgent.first_name,
        last_name: this.getAgent.last_name,
        phone: this.getAgent.phone,
        is_active: this.getAgent.is_active,
      };
    },
  },
  methods: {
    ...mapMutations("admin", ["updateAgentDetails"]),
    openPiggy(piggy) {
      this.piggy = piggy;
      this.dialog = true;
    },

    returnIntials(user) {
      let stringArray = user.split(" ");
      let firstInitial = stringArray[0].substring(0, 1);
      let secondInitial = stringArray[1].substring(0, 1);
      return firstInitial + secondInitial;
    },
    suspendAgent() {
      this.openSuspendModal = !this.openSuspendModal;
    },
    formatDate(date) {
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let month = newDate.getMonth() + 1;
      let dt = newDate.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      return dt + "/" + month + "/" + year;
    },
    closemodal() {
      this.dialog = false;
    },
    closeCreateModal() {
      this.createDialog = false;
    },
    openCreateModal() {
      this.createDialog = true;
    },
    transformDate(databasedate) {
      let date = new Date(databasedate);
      return date.toDateString();
    },
    goBack() {
      this.$router.push("/admin/agents");
    },

    getTransactions() {
      this.transactionloading = true;
      let phone = this.$route.query.phone;
      getAgentTransactions(phone).then(
        (res) => {
          this.transactionloading = false;
          this.transactions = res.data;
        },
        (err) => {
          this.transactionloading = false;
          console.log(err);
        }
      );
    },
    getSavers() {
      this.saversloading = true;
      let phone = this.$route.query.phone;
      getAgentSavers(phone).then(
        (res) => {
          this.saversloading = false;
          this.savers = res.data;
        },
        (err) => {
          this.saversloading = false;
          console.log(err);
        }
      );
    },
    sendAgentToStore() {
      let agent = JSON.parse(sessionStorage.getItem("agentDetails"));
      this.updateAgentDetails(agent);
    },
  },
};
</script>

<style scoped>
.admin-actions {
  display: flex;
  justify-content: space-between;
}
.title p {
  font-size: 12px;
}
.transaction_table {
  max-height: 40vh;
  overflow-y: auto;
  contain: content;
}
.savers_table {
  max-height: 80vh;
  overflow-y: auto;
  contain: content;
}
.view_piggy {
  max-height: 85vh;
  contain: content;
  overflow-y: scroll;
}
.webView {
  padding: 10px 30px;
}
.mobileView {
  padding: unset;
}
.col {
  padding: unset !important;
}
.chip {
  padding: 5px 10px;
  background-color: #e8fcf1;
  border-radius: 5px;
  height: 50px;
}
.due_date {
  color: #959595;
  font-size: 11px;
  font-weight: 100;
}
.back_button {
  cursor: pointer;
  font-size: 12px !important;
}
.transaction_header {
  background-color: #f7f9fc;
  padding: 4px 4px;
}
.user_details p {
  font-size: 12px;
}
.piggy_title {
  font-size: 12px;
  color: grey;
}
.piggy_details p {
  font-size: 12px;
}
.mini_cards {
  background-color: #f7f9fc;
  border-radius: 2px;
  margin-right: 10px;
  width: 100%;
  padding: 10px 10px;
}
.mini_cards p {
  font-size: 12px;
}
.user
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 2px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(161, 161, 161) !important;
  border-radius: 2px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
.transaction_body {
  position: relative;
  height: 30vh;
  overflow-y: auto;
}
.bottom_card {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.transaction_header {
  background-color: #f7f9fc;
  padding: 4px 4px;
}
.transaction_header h5 {
  color: #272253;
}
.transaction_details {
  margin-left: 30px;
}
.transaction_details p {
  font-size: 12px;
}
.transaction_amount {
  position: absolute;
  right: 2rem;
}
</style>