<template>
  <div class="text-center">
    <v-dialog v-model="dialog" :width="returnWidth" persistent>
      <v-card :width="returnWidth" :height="returnHeight" class="px-8 piggy">
        <v-row justify="end">
          <v-btn icon color="black" @click="closeModal" class="close_btn">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <div class="py-2 piggy_form">
          <div class="ml-3 header">
            <h5 class="mt-1">CREATE A PIGGY</h5>
          </div>
          <v-row>
            <v-col md="6" sm="12" cols="12">
              <p>Saver</p>
              <v-select
                v-model="saver"
                outlined
                :items="getSavers"
                solo
                label="Select Saver"
                item-text="detail.name"
                item-value="detail.phone"
                color="primary"
                :disabled="disableDropdown"
                :loading="saverloading"
              ></v-select>
              <span class="bottom_phone">{{ returnSaver }}</span>
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <p>Name of Piggy</p>
              <v-text-field
                v-model="piggyName"
                outlined
                solo
                label="Enter Piggy Name"
                color="primary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="12" cols="12">
              <p>Amount</p>
              <v-text-field
                v-model="amount"
                type="number"
                class="number_input"
                outlined
                solo
                label="Enter Amount to Save"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <p>Target Amount</p>
              <v-text-field
                type="number"
                v-model="targetAmount"
                outlined
                solo
                label="Input a Target?"
                color="primary"
                class="number_input"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="12" cols="12">
              <p>Saving Frequency</p>
              <v-select
                v-model="savingFrequency"
                outlined
                :items="frequencies"
                solo
                label="Pick Saving Number"
                color="primary"
              ></v-select>
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <p>Maturity Date</p>
              <v-menu
                v-model="dateDialog"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatedDate"
                    outlined
                    type="date"
                    solo
                    label="Set a Maturity Date"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  elevation="15"
                  @input="dateDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Reason For Saving</p>
              <v-text-field
                v-model="piggyReason"
                outlined
                solo
                label="What is this Piggy For?"
                color="primary"
              ></v-text-field>
            </v-col>
          </v-row>

          <div class="mb-1">
            <v-row>
              <v-col md="3">
                <v-btn
                  color="primary"
                  large
                  depressed
                  outlined
                  @click.prevent="() => closeModal()"
                >
                  CANCEL
                </v-btn>
              </v-col>
              <v-col md="3">
                <v-btn
                  color="primary"
                  :loading="loading"
                  large
                  @click.prevent="() => addpiggy()"
                >
                  CREATE PIGGY
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { createPiggy } from "@/agent/services/api-services";
export default {
  data() {
    return {
      dateDialog: false,
      loading: false,
      saverloading: false,
      disabled: true,
      disableDropdown: false,
      saver: "",
      piggyName: "",
      amount: "",
      targetAmount: "",
      savingFrequency: "",
      piggyReason: "",
      date: "",
      savers: [],
      frequencies: ["Yearly", "Monthly", "Weekly"],
    };
  },
  props: {
    dialog: Boolean,
    saverDetails: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters("customers", ["getSaverProfile", "getSavers"]),
    ...mapGetters("agent", ["agent"]),
    returnSaver() {
      if (this.saver.detail) {
        return this.saver.detail.phone;
      } else {
        return this.saver;
      }
    },
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
    formatedDate() {
      return this.date;
    },
    returnHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "80vh";
      } else {
        return "80vh";
      }
    },
  },
  watch: {
    dialog: function (newValue, oldValue) {
      if (newValue) {
        if (Object.keys(this.saverDetails).length > 0) {
          this.saver = this.saverDetails;
          this.disableDropdown = true;
        } else {
          if (!(this.getSavers.length > 0)) {
            this.saverloading = true;
            this.disableDropdown = true;
            this.getAllCustomers().then(
              (res) => {
                this.disableDropdown = false;
                this.saverloading = false;
              },
              (err) => {
                this.disableDropdown = false;
                this.saverloading = false;
              }
            );
          }
        }
      }
    },
  },
  methods: {
    ...mapMutations("customers", ["saveUserProfile", "addPiggy"]),
    ...mapActions("customers", ["getAllCustomers"]),

    closeModal() {
      this.$emit("closeModal");
      this.piggyName = "";
      this.amount = "";
      this.targetAmount = "";
      this.savingFrequency = "";
      this.piggyReason = "";
      this.date = "";
    },
    addpiggy() {
      let data = {
        title: this.piggyName,
        saving_frequency: this.savingFrequency,
        amount: this.amount,
        target_amount: this.targetAmount,
        description: this.piggyReason,
        maturity_date: this.date,
        phone: this.returnSaver,
        agent_phone: this.agent.user_id,
      };

      this.loading = true;
      createPiggy(data).then(
        (res) => {
          this.loading = false;
          this.addPiggy(res.data);
          this.closeModal();
        },
        (err) => {
          this.loading = false;
          console.error(err);
          this.closeModal();
        }
      );
    },
    back() {
      this.$emit("updateStep", 1);
    },
  },
};
</script>

<style scoped>
.bottom_phone {
  font-size: 12px;
  margin-left: 10px;
}
.number_input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.number_input >>> input::-webkit-outer-spin-button,
.number_input >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.piggy
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.piggy {
  contain: content;
  overflow-y: auto;
}
.piggy >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.piggy_form p {
  color: #272253;
  font-size: 13px;
  font-weight: 500;
}
.piggy_form >>> label,
input {
  font-size: 12px !important;
}

.header p {
  font-size: 10px;
  line-height: 18px;
}
</style>