<template>
  <div>
    <AddSaverModal :open="open">
      <template v-slot:button>
        <v-card
          :color="color"
          :elevation="2"
          class="add_saver"
          @click.prevent="openModal()"
        >
          <h5>Add Saver</h5>
          <img
            src="../../../../assets/plus.svg"
            alt=""
            height="30"
            class="card_image"
          />
        </v-card>
      </template>
    </AddSaverModal>
  </div>
</template>

<script>
import AddSaverModal from "../../savers/AddSaver";
export default {
  data() {
    return {
      open: false,
      color: "#DAEDFF",
    };
  },

  components: {
    AddSaverModal,
  },
  methods: {
    openModal() {
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
.add_saver {
  height: 12vh !important;
  padding: 15px;
}
.card_image {
  position: absolute;
  right: 1rem;
  bottom: 0;
}
</style>