import { render, staticRenderFns } from "./AddSaver.vue?vue&type=template&id=795165ba&scoped=true&"
import script from "./AddSaver.vue?vue&type=script&lang=js&"
export * from "./AddSaver.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795165ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VDialog,VSlideXTransition})
