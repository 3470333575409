<template>
  <div>
    <v-skeleton-loader :loading="loading" type="image">
      <v-card color="primary" flat class="wallet_card">
        <h5>WALLET BALANCE</h5>
        <div class="d-flex balance">
          <v-icon color="white"> mdi-currency-ngn </v-icon>
          <span>{{ getBalance }}</span>
        </div>
        <div class="wallet_actions d-flex">
          <AddMoney />
          <WithdrawFunds v-show="getBalance > 0" :balance="getBalance" />
        </div>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script>
import Paystack from "vue-paystack";
import FundWallet from "./fundWallet/Card";
import WithdrawFunds from "./WithdrawFunds";
import AddMoney from "./AddMoney";
import {
  getBalance,
  initializeTransaction,
  validateTransaction,
} from "../../../services/api-services";
import { mapMutations, mapGetters } from "vuex";
// import FundWallet from "./fundWallet/FundWallet";
export default {
  data() {
    return {
      balance: 0,
      dialog: false,
      withdrawDialog: false,
      loading: false,
      amount: "",
      pin: "",
      paymentReference: null,
      PUBLIC_KEY: "pk_test_d1309deb0fa7ab07f4f5f32e5d75f519965025af",
    };
  },
  components: {
    FundWallet,
    Paystack,
    WithdrawFunds,
    AddMoney,
  },
  created() {
    let user = JSON.parse(sessionStorage.getItem("ajoData"));
    this.getWalletBalance(user.user_id);
  },
  computed: {
    ...mapGetters("agent", ["getBalance"]),
    email() {
      let user = JSON.parse(sessionStorage.getItem("ajoData"));
      return user.email;
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      this.paymentReference = text;
      console.log(this.paymentReference);

      return text;
    },
  },
  methods: {
    ...mapMutations("agent", ["updateBalance"]),
    fundUserWallet(data) {
      this.dialog = false;
      this.amount = data.amount;
      this.pin = data.pin;
      this.$refs.paystack.$el.click();
    },
    openPayment() {
      this.$refs.paystack.$el.click();
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    openWithdrawDialog() {
      this.withdrawDialog = true;
    },
    closeWithdrawDialog() {
      this.withdrawDialog = false;
    },
    getWalletBalance(data) {
      this.loading = true;
      getBalance(data).then(
        (res) => {
          this.loading = false;
          this.updateBalance(res.data.balance);
          this.balance = res.data.balance;
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    },
    processPayment() {
      let data = {
        reference: this.paymentReference,
      };
      this.loading = true;
      validateTransaction(data)
        .then(() => {
          this.loading = false;
          this.balance = +this.balance + +this.amount;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    close() {
      console.log("You closed checkout page");
    },
  },
};
</script>

<style scoped>
.balance span {
  color: white;
  font-size: 23px;
}
.wallet_card {
  padding: 25px 25px;

  height: 25vh;
  contain: content;
}
.wallet_card h5 {
  font-size: 10px;
  letter-spacing: 0.2em;
}
.wallet_actions {
  position: absolute;
  bottom: 1rem;
}
</style>