<template>
  <div>
    <v-row class="mt-4 atm_card">
      <!-- <v-col md="6" sm="12" cols="12">
        <div class="d-flex">
          <span>SELECT CARD</span>
          <h5 class="add_card" @click="() => addCard()">+ ADD CARD</h5>
        </div>
        <div v-if="!(cards.length > 0)">
          <p>You have not setup any card yet.</p>
        </div>
        <div class="mt-2" v-else>
          <div
            class="cards d-flex mb-2"
            v-for="(card, index) in cards"
            :key="index"
          >
            <img :src="card.logo" alt="" />
            <p class="ml-4">{{ card.number }}</p>
          </div>
        </div>
      </v-col> -->
      <v-col md="12" sm="12" cols="12" align="center" justify="center">
        <div v-if="!(cards.length > 0)">
          <img
            src="../../../../../assets/atmCards.svg"
            alt=""
            class="mt-4"
            :height="height"
          />
        </div>
        <div v-else>
          <h5 class="field_title">Amount</h5>
          <v-text-field
            v-model="amount"
            outlined
            color="primary"
          ></v-text-field>

          <h5 class="field_title">Enter your pin</h5>
          <v-text-field
            v-model="pin"
            outlined
            color="primary"
            class="pin"
          ></v-text-field>

          <v-btn
            large
            width="100%"
            class="my-4"
            color="primary"
            @click.prevent="fundwallet()"
            >FUND WALLET</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amount: "",
      pin: "",
      cards: [
        {
          number: "4344 5555 **** 4444",
          logo: require("../../../../../assets/mastercard.svg"),
        },
        {
          number: "4344 5555 **** 4444",
          logo: require("../../../../../assets/verve.svg"),
        },
        {
          number: "4344 5555 **** 4444",
          logo: require("../../../../../assets/visa.svg"),
        },
      ],
    };
  },
  methods: {
    addCard() {
      this.$emit("addCard");
    },
    fundwallet() {
      this.$emit("fundwallet", { amount: this.amount, pin: this.pin });
    },
  },
  computed: {
    height() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "150px";
      } else {
        return "150px";
      }
    },
  },
};
</script>

<style scoped>
.atm_card
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 600;
}
.atm_card >>> fieldset {
  border: unset !important;
}
.pin >>> fieldset {
  border: 1px solid #272253 !important;
}
.title_text {
  font-size: 18px;
  color: #272253;
  letter-spacing: 1px;
}
.atm_card >>> .col,
.col-12 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 20px !important;
}
.atm_card span {
  font-size: 11px;
}
.atm_card p {
  font-size: 10px;
}
.add_card {
  cursor: pointer;
  position: relative;
  margin-left: 48%;
}
.cards {
  padding: 15px 15px;
  border: 1px solid rgb(231, 228, 228);
  border-radius: 5px;
}
.field_title {
  text-align: left !important;
}
.atm_card >>> .v-btn {
  height: 50px !important;
}
</style>