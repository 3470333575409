<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          color="white"
          class="mr-3"
          v-bind="attrs"
          v-on="on"
          @click.prevent="openDialog"
          >+ ADD MONEY</v-btn
        >
      </template>
      <v-card class="pl-4 py-4">
        <v-row justify="end">
          <v-btn icon color="black" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <FundWallet @fundwallet="(details) => fundUserWallet(details)" />
      </v-card>
    </v-dialog>
    <paystack
      :amount="amount * 100"
      :email="this.email"
      :paystackkey="PUBLIC_KEY"
      :reference="reference"
      :callback="processPayment"
      :close="close"
      ref="paystack"
      v-show="false"
    >
      Make Payment
    </paystack>
  </div>
</template>

<script>
import Paystack from "vue-paystack";
import FundWallet from "./fundWallet/Card";
import { validateTransaction } from "../../../services/api-services";
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      amount: "",
      pin: "",
      paymentReference: null,
      PUBLIC_KEY: "pk_test_d1309deb0fa7ab07f4f5f32e5d75f519965025af",
    };
  },
  components: {
    FundWallet,
    Paystack,
  },
  computed: {
    ...mapGetters("agent", ["getBalance"]),
    email() {
      let user = JSON.parse(sessionStorage.getItem("ajoData"));
      return user.email;
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      this.paymentReference = text;
      console.log(this.paymentReference);

      return text;
    },
  },
  methods: {
    ...mapMutations("agent", ["updateBalance"]),
    fundUserWallet(data) {
      this.dialog = false;
      this.amount = data.amount;
      this.pin = data.pin;
      this.$refs.paystack.$el.click();
    },
    openPayment() {
      this.$refs.paystack.$el.click();
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    processPayment() {
      let data = {
        reference: this.paymentReference,
      };

      validateTransaction(data)
        .then(() => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Transaction Successful",
            success: true,
          });
          this.updateBalance(+this.getBalance + +this.amount);
        })
        .catch((err) => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Transaction Unsuccessful",
            success: false,
          });
          console.log(err);
        });
    },
    close() {
      console.log("You closed checkout page");
    },
  },
};
</script>

<style>
</style>