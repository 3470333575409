<template>
  <v-card :width="returnWidth" class="px-8 saver" :loading="loading">
    <v-row justify="end">
      <v-btn icon color="black" @click="closeModal" class="close_btn mt-4">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <div class="py-2 savers_form">
      <h5 class="ml-3">Add a Saver</h5>
      <v-row class="mt-6">
        <v-col md="6" sm="12">
          <p>First Name</p>
          <v-text-field
            v-model="firstname"
            outlined
            solo
            label="Enter First Name"
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col md="6" sm="12">
          <p>Last Name</p>
          <v-text-field
            v-model="lastname"
            outlined
            solo
            label="Enter Last Name"
            color="primary"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" sm="12">
          <p>Phone Number</p>
          <v-text-field
            v-model="phonenumber"
            outlined
            type="number"
            solo
            class="number_input"
            label="Enter Phone Number"
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col md="6" sm="12">
          <p>Address</p>
          <v-text-field
            v-model="address"
            outlined
            solo
            label="Enter Address"
            color="primary"
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="my-4">
        <v-btn
          color="primary"
          class="mx-4"
          large
          depressed
          outlined
          @click.prevent="() => cancel()"
        >
          CANCEL
        </v-btn>
        <v-btn
          color="primary"
          class="mx-2"
          large
          @click.prevent="() => addSaver()"
        >
          ADD SAVER
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import { addSaver } from "../../../services/api-services";

export default {
  data() {
    return {
      loading: false,
      firstname: "",
      lastname: "",
      phonenumber: "",
      address: "",
    };
  },
  props: {
    dialog: Boolean,
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
  },
  methods: {
    ...mapMutations("customers", ["saveUserProfile"]),
    closeModal() {
      this.$emit("closeModal");
    },
    cancel() {
      this.closeModal();
      this.firstname = "";
      this.lastname = "";
      this.phonenumber = "";
      this.address = "";
    },
    addSaver() {
      let data = {
        phone: this.phonenumber,
        password: "123pass321",
        address: this.address,
        first_name: this.firstname,
        last_name: this.lastname,
      };
      this.saveUserProfile(data);
      this.$emit("openProfileModal");
      this.$emit("closeAddSaverModal");

      // this.loading = true;
      // addSaver(data).then(
      //   (res) => {
      //     this.loading = false;
      //     sessionStorage.setItem("saver", JSON.stringify(res.data));
      //     this.$emit("openModal");
      //     this.$emit("closeModal");
      //   },
      //   (err) => (this.loading = false)
      // );
    },
  },
};
</script>

<style scoped>
.number_input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.number_input >>> input::-webkit-outer-spin-button,
.number_input >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.saver {
  contain: content;
}
.saver
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.saver >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.savers_form p {
  color: #272253;
  font-size: 13px;
  font-weight: 500;
}
.savers_form >>> label,
input {
  font-size: 12px !important;
}
</style>