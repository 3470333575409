<template>
  <v-dialog v-model="dialog" persistent :width="returnWidth">
    <v-card :width="returnWidth" class="pa-6 pin" rounded="lg">
      <div align="left" justify="left" class="mb-10">
        <h4>Confirm Details</h4>

        <v-btn icon color="black" @click="closeModal" class="close_btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-row v-if="Object.entries(transactionDetails).length > 0">
        <v-col
          sm="6"
          v-for="[key, value] in Object.entries(transactionDetails)"
          :key="key"
          class="transactionInfo my-6 mr-4"
        >
          <label>{{ key }}</label>
          <h5>{{ value }}</h5>
        </v-col>
      </v-row>

      <span>Enter Pin</span>
      <div class="d-flex pin">
        <v-text-field
          class="mr-4"
          v-for="(pin, index) in newPin"
          :ref="`new${index}`"
          :key="index"
          placeholder="-"
          v-model="pin.field"
          outlined
          color="primary"
          @input="focusInput(index)"
        ></v-text-field>
      </div>

      <div align="center" justify="center">
        <v-btn
          color="primary"
          class="btn"
          :loading="loading"
          @click.prevent="withdraw"
          :disabled="disableBtn"
          depressed
        >
          VALIDATE PAYMENT
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      transactionDetails: {},
      newPin: [
        { field: "", disable: false },
        { field: "", disable: false },
        { field: "", disable: false },
        { field: "", disable: false },
      ],

      color: "#f7f9fc",
      files: [],
      image_src: "",
      disableBtn: true,
      pin: "",
      loading: false,
    };
  },
  props: {
    dialog: Boolean,
    transaction: Object,
    transact: Function,
    details: Object,
  },

  methods: {
    logData(data) {
      console.log(data);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    withdraw() {
      let data = this.transaction;
      this.newPin.forEach((element) => {
        this.pin = this.pin + element.field;
      });
      data.pin = parseInt(this.pin);
      this.loading = true;
      this.transact(data).then(
        (res) => {
          this.loading = false;
          this.closeModal();
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Transaction Successful",
            success: true,
          });
        },
        (err) => {
          this.loading = false;
          console.error(err);
          this.closeModal();
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Transaction Unsuccessful",
            success: false,
          });
        }
      );
    },
    focusInput(index) {
      if (this.newPin[index].field !== "" && index !== 3) {
        this.$refs["new" + (index + 1)][0].focus();
      }
      this.validatePin();
    },

    validatePin() {
      this.newPin.forEach((element, index) => {
        if (element.field === "") {
          this.newPin[index].disable = true;
        } else {
          this.newPin[index].disable = false;
        }
      });

      this.disableBtn =
        this.newPin[0].disable ||
        this.newPin[1].disable ||
        this.newPin[2].disable ||
        this.newPin[3].disable;
    },
  },
  watch: {
    details(newValue, oldValue) {
      this.transactionDetails = newValue;
    },
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },

    firstName() {
      return "UJAY";
    },
  },
};
</script>

<style scoped>
.transactionInfo label {
  text-transform: uppercase;
  color: rgb(165, 165, 165);
  font-size: 14px;
}
.file_drag {
  cursor: pointer;
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: 4px;
}
.btn {
  width: 100%;
  height: 3rem !important;
}
.pin {
  contain: content;
}
.pin p {
  margin: 5px 0;
  line-height: 18px;
  font-size: 12px;
  color: #272253;
}
.pin span {
  font-size: 12px;
  font-weight: 600;
}
.pin >>> .col {
  padding: 0 !important;
}
.pin
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 12px;
  margin-bottom: 2rem;
}
.pin >>> input {
  text-align: center;
}
.pin >>> fieldset {
  border: unset;
}
</style>