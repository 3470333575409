<template>
  <v-row>
    <v-col md="9" sm="12">
      <v-card class="card_left" rounded="lg">
        <div class="mt-4 d-flex">
          <div md="7" sm="6">
            <h4 class="top_card_title">Agents</h4>
          </div>
          <v-spacer></v-spacer>
          <div md="2" sm="6">
            <AddAgent :open="open">
              <template v-slot:button>
                <v-btn color="primary btn_header mr-2">+ ADD AGENT</v-btn>
              </template>
            </AddAgent>
          </div>
        </div>

        <div class="d-flex insight_cards mt-2">
          <div class="all_saver">
            <p>ALL AGENTS</p>
            <h3>4</h3>
          </div>
          <div class="active_saver">
            <p>ALL SAVERS</p>
            <h3>1</h3>
          </div>
          <div class="active_saver">
            <p>EARNINGS</p>
            <h4>
              <v-icon color="primary" small> mdi-currency-ngn </v-icon>50000
            </h4>
          </div>
        </div>

        <v-text-field
          class="mt-4"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          solo
          label="Search"
        ></v-text-field>
        <v-divider class="my-4"></v-divider>
        <div class="agents md-px-6">
          <v-row justify="end" v-show="selected.length > 0">
            <SuspendAgent :open="openSuspendModal" :agents="selected">
              <template v-slot:button>
                <v-btn
                  color="primary"
                  outlined
                  class="my-4"
                  @click="suspendAgent()"
                >
                  SUSPEND AGENT
                </v-btn>
              </template>
            </SuspendAgent>
          </v-row>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="getAgentsDetails"
            :single-select="singleSelect"
            :search="search"
            item-key="phone"
            show-select
            :loading="loading"
            class="elevation-1 agent_table"
            mobile-breakpoint="0"
            @click:row="(agent) => agentDetails(agent)"
          >
            <template v-slot:[`item.agent`]="{ item }">
              <div class="d-flex agent_details">
                <v-avatar color="#EEECFF" size="40">
                  <h5>
                    <!-- {{
                      returnIntials(
                        (agent = item.first_name + " " + item.last_name)
                      )
                    }} -->
                    AG
                  </h5>
                </v-avatar>
                <div class="ml-2">
                  <h4>Agent</h4>
                  <p>{{ item.phone }}</p>
                </div>
              </div>
            </template>
            <template v-slot:[`item.balance`]="{ item }">
              <h5>
                <v-icon color="primary" small> mdi-currency-ngn </v-icon
                >{{ item.balance }}
              </h5>
            </template>
            <template v-slot:[`item.savers`]="{ item }">
              <h5>
                {{ item.savers }}
              </h5>
            </template>
            <template v-slot:[`item.last_transaction`]="{ item }">
              <h5>
                {{ formatDate(item.last_transaction) }}
              </h5>
            </template>
            <template v-slot:[`item.created`]="{ item }">
              <h5>
                {{ formatDate(item.created) }}
              </h5>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
    <v-col md="3" sm="12">
      <v-card class="card_right" rounded="lg">
        <CreatePiggy />
        <MakePayment />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CreatePiggy from "@/agent/components/dashboard/quickActions/CreatePiggy.vue";
import MakePayment from "@/agent/components/dashboard/quickActions/PayBills.vue";
import { getAgents } from "../../services/api-services";
import AddAgent from "./AddAgent";
import SuspendAgent from "./SuspendAgent";
export default {
  data() {
    return {
      openSuspendModal: false,
      loading: false,
      open: false,
      selected: [],
      step: 1,
      addSaverModal: false,
      verifySaverModal: false,
      profileModal: false,
      singleSelect: false,
      page: 1,
      filteredSearch: [],
      headers: [
        {
          text: "AGENT",
          align: "start",
          sortable: false,
          value: "agent",
        },
        { text: "SAVERS", value: "savers" },
        { text: "BALANCE", value: "balance" },
        { text: "LAST TRANS", value: "last_transaction" },
        { text: "JOINED", value: "created" },
      ],

      search: "",
    };
  },
  components: {
    CreatePiggy,
    MakePayment,
    AddAgent,
    SuspendAgent,
  },
  created() {
    this.getAllAgents();
  },

  methods: {
    ...mapMutations("admin", ["updateAgents"]),
    openModal() {
      this.open = !this.open;
    },
    formatDate(date) {
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let month = newDate.getMonth() + 1;
      let dt = newDate.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      return dt + "/" + month + "/" + year;
    },
    suspendAgent() {
      this.openSuspendModal = !this.openSuspendModal;
    },
    returnIntials(user) {
      let stringArray = user.split(" ");
      let firstInitial = stringArray[0].substring(0, 1);
      let secondInitial = stringArray[1].substring(0, 1);
      return firstInitial.toUpperCase() + secondInitial.toUpperCase();
    },
    agentDetails(agent) {
      sessionStorage.setItem("agentDetails", JSON.stringify(agent));
      this.$router.push(`/admin/agent/?phone=${agent.phone}`);
    },
    getAllAgents() {
      this.loading = true;
      getAgents().then(
        (res) => {
          this.loading = false;
          this.updateAgents(res.data);
        },
        (err) => {
          this.loading = false;
        }
      );
    },
  },

  computed: {
    ...mapGetters("admin", ["getAgentsDetails"]),
    start() {
      let a = this.page * 4;
      let b = 4;
      return a - b;
    },
    end() {
      return this.page * 4;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(59, 58, 58) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
.agent_table >>> tbody {
  cursor: pointer;
}
.empty_users {
  text-align: -webkit-center;
  height: 40vh;
  margin-top: 5rem;
}
.agent_details h4 {
  margin-top: 2px;
}
.agents p {
  color: rgb(165, 165, 165);
  font-size: 12px;
  font-weight: 500;
}
.savers_details {
  position: relative;
  height: 40vh;
  overflow-y: auto;
}
.card_left
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 2px 8px 16px rgba(212, 212, 212, 0.5);
}

.insight_cards div {
  background-color: #f7f9fc;
  margin-right: 0.5rem;
  border-radius: 8px;
  padding: 10px;
  width: 30vw;
}

.insight_cards div > p {
  font-size: 12px;
}
.card_left {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.card_right {
  padding: 20px 20px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
  height: 30vh;
}
</style>