<template>
  <v-dialog v-model="dialog" persistent :width="returnWidth">
    <v-card :width="returnWidth" class="px-4 pt-3 saver" v-if="!loading">
      <v-row justify="end">
        <v-btn icon color="black" @click="closeModal" class="close_btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
      <div class="py-2 savers_form">
        <div class="ml-3 header">
          <h5>Verify Saver's Number</h5>
          <p class="mt-2">
            A 10-digit token has been sent to 0806 670 1121 to verify user
            number with the token now.
          </p>
        </div>

        <v-row class="mt-6">
          <v-col md="6" sm="12">
            <h5>Token</h5>
            <v-text-field
              v-model="token"
              :loading="verifyloading"
              outlined
              solo
              label="Enter Token"
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col md="6" sm="12">
            <img src="@/assets/verify.svg" :height="height" />
          </v-col>
        </v-row>

        <div class="my-4">
          <v-btn
            color="primary"
            class="mx-2"
            large
            @click="() => verifyNumber()"
          >
            VERIFY NUMBER
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card :width="returnWidth" class="px-4 saver" v-else :loading="loading">
      <v-row justify="center" class="py-10">
        <h5>Sending Verification Token</h5>
      </v-row>
    </v-card>
    ></v-dialog
  >
</template>

<script>
import { verifyToken, sendToken } from "../../../services/api-services";
export default {
  data() {
    return {
      loading: false,
      verifyloading: false,
      token: "",
    };
  },
  props: {
    dialog: Boolean,
  },
  // watch: {
  //   dialog(newValue, oldValue) {
  //     if (newValue) {
  //       this.sendUserToken();
  //     }
  //   },
  // },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "45vw";
      }
    },
    height() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "60px";
      } else {
        return "100px";
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.token = "";
    },
    sendUserToken() {
      let number = JSON.parse(sessionStorage.getItem("token")).message;
      let data = {
        recipient: number,
      };

      sendToken(data).then(
        (res) => {
          this.loading = false;
          sessionStorage.setItem("token", JSON.stringify(res.data));
          console.log(res);
        },
        (err) => {
          this.loading = false;
          console.error(err);
        }
      );
    },
    //   created() {
    //   if (this.$route.query.phone !== undefined) {
    //     this.sendUserToken();
    //   } else {
    //     console.log("No Number");
    //   }
    // },
    verifyNumber() {
      // let request_id = JSON.parse(sessionStorage.getItem("token")).message;
      // // let number = "0" + this.$route.query.phone.toString().substring(4);

      // let data = {
      //   request_id,
      //   code: this.token,
      //   phone: number,
      // };
      // this.verifyloading = true;
      this.$emit("closeModal");
      this.$emit("openModal");
      // verifyToken(data, 8).then(
      //   (res) => {
      //     this.verifyloading = false;
      //     this.$emit("closeModal");
      //     this.$emit("openModal");
      //     console.log(res);
      //     this.$router.push({ path: "/verify", query: { type: "success" } });
      //   },
      //   (err) => {
      //     this.verifyloading = false;
      //     console.error(err);
      //   }
      // );
    },
  },
};
</script>

<style scoped>
.saver
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #f7f9fc;
  border-radius: 5px;
  box-shadow: unset !important;
}
.saver >>> fieldset {
  border-color: white;
  border-radius: 5px;
}
.savers_form p {
  color: #272253;
  font-size: 13px;
  font-weight: 500;
}
.savers_form >>> label,
input {
  font-size: 12px !important;
}
.header {
  width: 330px;
}
.header p {
  font-size: 13px;
  line-height: 18px;
}
</style>