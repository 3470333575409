<template>
  <v-card
    :width="returnWidth"
    class="bottom_card mr-6 px-6 pb-4"
    :loading="loading"
  >
    <h4 class="mt-4">Recent Transactions</h4>
    <div class="transaction_header mt-6">
      <h5>TODAY</h5>
    </div>
    <div v-if="!(todayTransactions.length > 0)" class="mt-6">
      <v-img
        src="../../../../assets/emptyUser.svg"
        width="80"
        height="80"
      ></v-img>
      <h5>No Transaction</h5>
      <h5>Made yet</h5>
    </div>
    <div class="transaction_body my-4" v-else>
      <div
        class="d-flex my-4"
        v-for="(transaction, index) in todayTransactions"
        :key="index"
      >
        <v-avatar
          :color="
            transaction.verified
              ? transactionType(transaction.transaction_type)
                ? '#CCCCFF'
                : '#D2FFE7'
              : '#ffdbe7'
          "
          size="34"
        >
          <v-icon
            :color="
              transaction.verified
                ? transactionType(transaction.transaction_type)
                  ? '#1F51FF'
                  : '#13C7A6'
                : 'red'
            "
            small
          >
            mdi-wallet
          </v-icon>
        </v-avatar>
        <div class="transaction_details">
          <h5>{{ transaction.transaction_type }}</h5>
          <p>{{ transformDate(transaction.created) }}</p>
        </div>
        <div class="transaction_amount">
          <h5>
            <v-icon color="primary" small class="mb-1">
              mdi-currency-ngn </v-icon
            >{{ transaction.transaction_amount }}
          </h5>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      loading: false,
      transactions: [
        {
          type: "Wallet Funded",
          amount: 30000,
        },
        {
          type: "Airtime Topup",
          amount: 40000,
        },
        {
          type: "Wallet Funded",
          amount: 30000,
        },
        {
          type: "Airtime Topup",
          amount: 40000,
        },
      ],
    };
  },
  created() {
    this.getTransactions();
  },
  computed: {
    ...mapGetters("agent", ["agentTransactions"]),
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "38vw";
      }
    },
    transactionType() {
      return function (type) {
        return type === "Utility";
      };
    },
    todayTransactions() {
      let date = new Date(2021, 5, 2).toLocaleDateString("en-US");
      let newTransactions = [...this.agentTransactions];

      return newTransactions.filter(
        (transaction) =>
          new Date(transaction.created).toLocaleDateString("en-US") === date
      );
    },
  },
  methods: {
    ...mapActions("agent", ["getAgentTransactions"]),
    transformDate(databasedate) {
      let date = new Date(databasedate);
      return date.toDateString();
    },
    getTransactions() {
      this.loading = true;
      let user = JSON.parse(sessionStorage.getItem("ajoData"));
      this.getAgentTransactions(user.user_id).then(
        (res) => {
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 2px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(161, 161, 161) !important;
  border-radius: 2px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
.transaction_body {
  position: relative;
  height: 30vh;
  overflow-y: auto;
}
.bottom_card {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04) !important;
}
.transaction_header {
  background-color: #f7f9fc;
  padding: 4px 4px;
}
.transaction_header h5 {
  color: #272253;
}
.transaction_details {
  margin-left: 30px;
}
.transaction_details p {
  font-size: 12px;
}
.transaction_amount {
  position: absolute;
  right: 2rem;
}
</style>